import {useNavigate, useParams} from "react-router-dom";
import useApiCall from "../hooks/CancellableApiCall";
import {LoadingContent} from "../components/LoadingContent";
import {Box, Divider, Paper, Stack, Typography} from "@mui/material";
import {EditorContent} from "../components/editor/EditorContent";
import {AnnouncementResponse} from "../data/AnnouncementResponse";
import React, {useEffect} from "react";
import {AnnouncementSubtitle} from "../components/announcement/AnnouncementPreview";

export const AnnouncementPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const loadData = useApiCall<AnnouncementResponse>({
        url: `/api/announcement/${params.id}`
    })

    let announcement;
    if (loadData.data) {
        announcement = (
            <Stack spacing={0.5}>
                <Typography variant={"h6"}>
                    {loadData.data.title}
                </Typography>
                <AnnouncementSubtitle announcement={loadData.data}/>
                <Divider/>
                <Box sx={{pl: 2, pr: 2, pb: 2}}>
                    <EditorContent content={loadData.data?.content ?? ""}></EditorContent>
                </Box>
            </Stack>
        )
    }

    useEffect(() => {
        if (loadData.isError) {
            navigate("/")
        }
    }, [loadData.isError, navigate]);

    return (
        <LoadingContent isLoading={loadData.isLoading}>
            <Paper variant={"outlined"} sx={{p: 2, minHeight: 200}}>
                {announcement}
            </Paper>
        </LoadingContent>
    )
}