import React from "react";
import {
    Box,
    Button,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import useApiCall from "../../hooks/CancellableApiCall";
import useUser from "../../hooks/useUser";
import {LoadingContent} from "../../components/LoadingContent";
import {AccountResponse} from "../../data/AccountResponse";
import {
    InternalAffairsLimitedComplaintResponse
} from "../../data/internal-affairs/InternalAffairsLimitedComplaintResponse";
import {formatTimestamp} from "../../utils";
import {Link as RouterLink} from "react-router-dom";
import {InternalAffairsStatementResponse} from "../../data/internal-affairs/InternalAffairsStatementResponse";
import {StatementRow} from "./InternalAffairsComplaintPage";
import {
    InternalAffairsComplaintClassification
} from "../../data/internal-affairs/InternalAffairsComplaintClassification";
import {InternalAffairsComplaintStatus} from "../../data/internal-affairs/InternalAffairsComplaintStatus";
import {InternalAffairsComplaintOutcome} from "../../data/internal-affairs/InternalAffairsComplaintOutcome";
import {RecordTypes} from "../../data/internal-affairs/RecordTypes";

interface PersonalComplaintsDashboardProps {
    user: AccountResponse
    statements: InternalAffairsStatementResponse[]
}

interface ComplaintRowProps {
    complaint: InternalAffairsLimitedComplaintResponse
}

const ComplaintRow = (props: ComplaintRowProps) => {
    return <TableRow>
        <TableCell> {props.complaint.displayId ?? props.complaint.id} </TableCell>
        <TableCell> {props.complaint.defendantRpName} </TableCell>
        <TableCell> {InternalAffairsComplaintClassification.get(props.complaint.classification ?? "pending")?.displayName} </TableCell>
        <TableCell> {InternalAffairsComplaintStatus.get(props.complaint.status)?.displayName} </TableCell>
        <TableCell> {InternalAffairsComplaintOutcome.get(props.complaint.outcome ?? "pending")?.displayName} </TableCell>
        <TableCell> {RecordTypes.get(props.complaint.actionTaken ?? "")?.displayName} </TableCell>
        <TableCell> {formatTimestamp(props.complaint.creationDate)} </TableCell>
    </TableRow>
}

function PersonalComplaintsDashboard(props: PersonalComplaintsDashboardProps) {
    const loadComplaintsOnUser = useApiCall<InternalAffairsLimitedComplaintResponse[]>({
        initialUrl: `/api/internal-affairs/on-me`
    })

    const loadComplaintsByUser = useApiCall<InternalAffairsLimitedComplaintResponse[]>({
        initialUrl: `/api/internal-affairs/by-me`
    })

    return <Stack spacing={1}>

        <Button variant="outlined" color="success" component={RouterLink} to={`/new-complaint/`}>Create new complaint</Button>


        <Typography sx={{pt:2}} variant="h6" gutterBottom>Open complaints against you</Typography>
        <Paper variant={"outlined"} style={{
            padding: "16px"
        }}>
            <Box sx={{overflowX:"auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Complaint ID</TableCell>
                        <TableCell>Defendant</TableCell>
                        <TableCell>Classification</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Outcome</TableCell>
                        <TableCell>Action Taken</TableCell>
                        <TableCell>Creation Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadComplaintsOnUser.data && loadComplaintsOnUser.data.map((complaint) => {
                        return <ComplaintRow complaint={complaint}/>
                    })
                    }
                </TableBody>
            </Table>
            </Box>
        </Paper>

        <Typography sx={{pt:2}} variant="h6" gutterBottom>Complaints you have made</Typography>
        <Paper variant={"outlined"} style={{
            padding: "16px"
        }}>
            <Box sx={{overflowX:"auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Complaint ID</TableCell>
                            <TableCell>Defendant</TableCell>
                            <TableCell>Classification</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Outcome</TableCell>
                            <TableCell>Action Taken</TableCell>
                            <TableCell>Creation Date</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loadComplaintsByUser.data && loadComplaintsByUser.data.map((complaint) => {
                            return <TableRow key={complaint.id}>
                                <TableCell> {complaint.displayId ?? complaint.id} </TableCell>
                                <TableCell>
                                    <Link underline="none"
                                          component={RouterLink}
                                          to={`/user/${complaint.defendantCommunityId}`}
                                    > {complaint.defendantName} </Link>
                                </TableCell>
                                <TableCell> {InternalAffairsComplaintClassification.get(complaint.classification ?? "pending")?.displayName} </TableCell>
                                <TableCell> {InternalAffairsComplaintStatus.get(complaint.status)?.displayName} </TableCell>
                                <TableCell> {InternalAffairsComplaintOutcome.get(complaint.outcome ?? "pending")?.displayName} </TableCell>
                                <TableCell> {RecordTypes.get(complaint.actionTaken ?? "")?.displayName} </TableCell>
                                <TableCell> {formatTimestamp(complaint.creationDate)} </TableCell>
                                <TableCell> <Button component={RouterLink} to={`/complaint/${complaint.displayId ?? complaint.id}`}
                                                    color="success">View Complaint</Button> </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Box>
        </Paper>
        <Typography sx={{pt:2}} variant="h6" gutterBottom>Your statements</Typography>
        <Paper variant="outlined" style={{
            padding: "16px"
        }}>
            <Box sx={{overflowX:"auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Statement ID</TableCell>
                            <TableCell>Provider Community ID</TableCell>
                            <TableCell>Date last Updated</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.statements && props.statements.map((statement) => {
                            return <StatementRow key={statement.id} user={props.user} statement={statement}/>
                        })}
                    </TableBody>
                </Table>
            </Box>
        </Paper>
    </Stack>
}

export const InternalAffairsPersonal = () => {
    const userData = useUser()
    const statementData = useApiCall<InternalAffairsStatementResponse[]>({
        url: "/api/internal-affairs/statement-request/"
    })

    const user = userData.user

    return (
        <LoadingContent isLoading={
            userData.isLoading || !user || statementData.isLoading
        }>
            <PersonalComplaintsDashboard statements={statementData.data!!} user={user!!}/>
        </LoadingContent>
    )
}