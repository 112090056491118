import React from "react";
import {Box, Button, Chip, Pagination, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {Link as RouterLink, useParams, useSearchParams} from "react-router-dom";
import {
    ApplicationMakerOverviewPageinationResponse
} from "../../data/applications/ApplicationMakerOverviewPageinationResponse";
import {formatTimestamp} from "../../utils";
import {UserApplicationStatus} from "../../data/applications/UserApplicationStatus";
import {PoliceUser} from "../../components/PoliceUser";

interface ApplicationMarkingOverviewPageContentProps {
    applicationId: number
    applicationData: ApplicationMakerOverviewPageinationResponse
}

function ApplicationMarkingOverviewPageContent(props: ApplicationMarkingOverviewPageContentProps) {
    let additionalAssessments = <>
        {
            props.applicationData?.assessmentsOnApplication?.map(assessment => {
                return <Button sx={{ml:1}} component={RouterLink}
                               to={`/careers/overview/${props.applicationId}/${assessment.id}?page=1`}
                               color="info">{assessment.title}</Button>
            })
        }
        <Button sx={{ml:1}} component={RouterLink}
                to={`/careers/overview/${props.applicationId}/passed?page=1`}
                color="success">Accepted</Button>
        <Button sx={{ml:1}} component={RouterLink}
                to={`/careers/overview/${props.applicationId}/failed?page=1`}
                color="error">Denied</Button>
    </>
    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        <Box sx={{overflowX: "auto"}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.applicationData?.applications?.map(applicant => {
                            let applicantStatus = UserApplicationStatus.get(applicant.applicationState)
                            let statusColor
                            if (applicantStatus?.displayName === "Failed" || applicantStatus?.displayName === "Withdrawn") {
                                statusColor = "red"
                            } else if (applicantStatus?.displayName=== "Passed") {
                                statusColor = "green"
                            } else if (applicantStatus?.displayName === "In Progress") {
                                statusColor = "#9333a4"
                            } else {
                                statusColor = "#f8ac59"
                            }

                            return <TableRow>
                                <TableCell> <PoliceUser linkProps={{variant: "subtitle1"}}
                                                        user={{nick: applicant.nick, communityId: applicant.communityId}}/> </TableCell>
                                <TableCell> {formatTimestamp(applicant.lastUpdateDate)} </TableCell>
                                <TableCell> <Chip color={"primary"} style={{backgroundColor: statusColor, height: "20px"}}
                                                  label={applicantStatus?.displayName}/> </TableCell>
                                <TableCell> <Button component={RouterLink}
                                                to={`/careers/mark/${props.applicationId}/${applicant.communityId}`}
                                                color="info">View Assessments</Button> </TableCell>
                            </TableRow>
                        }
                    )
                }
            </TableBody>
        </Table>
        </Box>
        <Box sx={{pt:2}}>
            {additionalAssessments}
        </Box>
    </Paper>
}

export const ApplicationMarkingOverviewPage = () => {
    const params = useParams()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const applicants = useApiCall<ApplicationMakerOverviewPageinationResponse>({
        url: `/api/careers/${params.careerId}/${params.stage}/${page}`
    })

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }

    const totalPages = applicants.data?.pages ?? 1

    return <LoadingContent
        isLoading={(applicants.isLoading || !applicants.data)}>
        <ApplicationMarkingOverviewPageContent
            applicationId={Number(params.careerId) ?? 0}
            applicationData={applicants.data!!}
        />
        <Box sx={{justifyContent: "center", display: "flex", mt: 2, width: "100%"}}>
            <Pagination color={'secondary'} count={totalPages} page={page} style={{padding: 8}}
                        onChange={handleChange}/>
        </Box>
    </LoadingContent>
}