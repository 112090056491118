import React, {useCallback, useRef, useState} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";
import useApiCall, {makeApiCall, UseApiCallResponseData} from "../../hooks/CancellableApiCall";
import {InternalAffairsComplaintResponse} from "../../data/internal-affairs/InternalAffairsComplaintResponse";
import {LoadingContent} from "../../components/LoadingContent";
import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    Link,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {formatTimestamp} from "../../utils";
import {TextEditor} from "../../components/editor/TextEditor";
import {$getRoot, LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import {InternalAffairsComplaintMessageResponse} from "../../data/internal-affairs/InternalAffairsComplaintMessageResponse";
import {EditorContent} from "../../components/editor/EditorContent";
import {InternalAffairsComplaintInvestigatorResponse} from "../../data/internal-affairs/InternalAffairsComplaintInvestigatorResponse";
import useUser from "../../hooks/useUser";
import {AccountResponse} from "../../data/AccountResponse";
import {MessageBoxWithUserDetails} from "../../components/MessageBoxWithUserDetails";
import {DivisionRoles} from "../../data/DivisionRoles";
import {InternalAffairsStatementResponse} from "../../data/internal-affairs/InternalAffairsStatementResponse";
import InternalAffairsNewStatementForm from "./InternalAffairsNewStatementForm";
import InternalAffairsStatementViewForm from "./InternalAffairsStatementViewForm";
import InternalAffairsStatementSubmitForm from "./InternalAffairsStatementSubmitForm";
import {InternalAffairsComplaintStatus, internalAffairsComplaintStatus} from "../../data/internal-affairs/InternalAffairsComplaintStatus";
import {internalAffairsComplaintClassification, InternalAffairsComplaintClassification} from "../../data/internal-affairs/InternalAffairsComplaintClassification";
import {InternalAffairsComplaintOutcome} from "../../data/internal-affairs/InternalAffairsComplaintOutcome";
import {AlertType} from "../../data/AlertType";

interface complaint {
    complaint: InternalAffairsComplaintResponse,
    complaintApiCall: UseApiCallResponseData<InternalAffairsComplaintResponse>
    complaintMessages: InternalAffairsComplaintMessageResponse[],
    investigators: InternalAffairsComplaintInvestigatorResponse[],
    user: AccountResponse,
    userIARoleDisplayName: string
}

interface investigator {
    investigatorIndex: number,
    complaint: InternalAffairsComplaintResponse
}

interface StatementRowProps {
    statement: InternalAffairsStatementResponse
    user: AccountResponse
}

export const StatementRow = (props: StatementRowProps) => {
    let option
    if (props.statement.status === "Pending Response" && props.statement.providerCommunityId === props.user.communityId) {
        option = <InternalAffairsStatementSubmitForm statement={props.statement}/>
    } else {
        option = <InternalAffairsStatementViewForm statement={props.statement}/>
    }

    return <TableRow>
        <TableCell> {props.statement.id} </TableCell>
        <TableCell> <Link underline="none"
                          component={RouterLink}
                          to={`/user/${props.statement.providerCommunityId}`}
        > {props.statement.providerRpName} ({props.statement.providerNick}) </Link>
        </TableCell>
        <TableCell> {formatTimestamp(props.statement.date)} </TableCell>
        <TableCell> {props.statement.status} </TableCell>
        <TableCell> {option} </TableCell>
    </TableRow>
}

function Complaint(props: complaint) {
    const [complaintStatus, setComplaintStatus] = useState(InternalAffairsComplaintStatus.get(props.complaint.status)?.key)
    const [complaintClassification, setComplaintClassification] = useState(InternalAffairsComplaintClassification.get(props.complaint.classification ?? "pending")?.key)
    const [assignedInvestigators, setAssignedInvestigators] = useState(props.complaint.assignee)
    const [complaintMessages, setComplaintMessages] = useState(props.complaintMessages)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [selectedInvestigatorIndex, setSelectedInvestigatorIndex] = useState<number>(0)
    const editorRef = useRef<LexicalEditor>(null)

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    let finalReportButton

    let userIaRank = ``
    if (props.userIARoleDisplayName !== "") {
        userIaRank = `<p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">${props.userIARoleDisplayName}</span></p>`
    }

    const initialContent = `<p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">Hello,</span></p><p class="editor-paragraph" dir="ltr" style="text-align: start;"><br></p><p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">Kind Regards,</span></p><p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">${props.user.rank?.displayName} ${props.user.rpName}</span></p>${userIaRank}`

    if (props.complaint.status === "final_reported" || props.complaint.status === "closed") {
        finalReportButton = <>
            <Button color="info"
                    disabled={props.user.communityId === props.complaint.creatorCommunityId && !props.complaint.complaintPermissions.includes("view_final_report") }
                    component={RouterLink} to={`/final-report/${props.complaint.displayId ?? props.complaint.id}`}>View Final
                Report</Button><Divider/>
        </>
    } else if (!props.complaint.complaintPermissions.includes("submit_final_report") || complaintClassification === "pending") {
                    finalReportButton = <>
                        <Tooltip title="You need to change the complaints classification first">
                          <center><span>
                            <Button disabled>Write Final Report</Button>
                          </span></center>
                        </Tooltip>
                        <Divider/>
                    </>
                } else {
                    finalReportButton = <>
                        <Button color="success"
                                component={RouterLink} to={`/final-report/${props.complaint.displayId ?? props.complaint.id}`}>
                            Write Final Report
                        </Button>
                    </>
                }

    let statementView = <></>
    if (props.complaint.complaintPermissions.includes("view_statements")) {
        statementView = <>

            <Typography sx={{pt:2}} variant="h6" gutterBottom>Statements</Typography>
            <Paper variant={"outlined"} style={{
                padding: "16px"
            }}>
                <Box sx={{overflowX:"auto"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Statement ID</TableCell>
                                <TableCell>Provider Community ID</TableCell>
                                <TableCell>Date last Updated</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.complaint.statements && props.complaint.statements.map((statement) => {
                                return <StatementRow key={statement.id} user={props.user} statement={statement}/>
                            })}
                        </TableBody>
                    </Table>
                </Box>
                <InternalAffairsNewStatementForm defaultTarget={props.complaint.defendantCommunityId}
                                                 id={props.complaint.id} parentApiCall={props.complaintApiCall}/>
            </Paper>
        </>
    }

    const updateClassification = (complaintId: number, classification: string) => {
        makeApiCall({
            url: `/api/internal-affairs/complaint/${complaintId}/classification`,
            method: "POST",
            body: {
                classification: classification
            },
            onLoadedCallback: () => {
                setAlert("success", "Classification Update")
                setComplaintClassification(classification)
            },
            onError: () => {
                setAlert("error", "Classification failed to update due to a issue")
            }
        })
    }

    const updateStatus = (complaintId: number, status: string) => {
        makeApiCall({
            url: `/api/internal-affairs/complaint/${complaintId}/status`,
            method: "POST",
            body: {
                status: status
            },
            onLoadedCallback: () => {
                setAlert("success", "Status Update")
                setComplaintStatus(status)
            },
            onError: () => {
                setAlert("error", "Status failed to update due to a issue")
            }
        })
    }

    const assignInvestigator = (complaintId: number, investigatorIndex: number) => {
        let investigator = props.investigators.filter(investigator => {
            return !invalidInvestigatorIds.includes(investigator.communityId);
        })[investigatorIndex]

        makeApiCall({
            url: `/api/internal-affairs/complaint/${complaintId}/assign`,
            method: "POST",
            body: {
                assigneeCommunityId: investigator?.communityId
            },
            onLoadedCallback: () => {
                setAssignedInvestigators([...assignedInvestigators, {
                    nick: investigator.nick,
                    rpName: investigator.rpName,
                    assigneeCommunityId: investigator.communityId
                }]);
                if (complaintStatus === "awaiting_investigator") {
                    setComplaintStatus("investigator_assigned")
                }
                setAlert("success", "Investigator assigned")
            },
            onError: () => {
                setAlert("error", "Investigated failed to update due to a issue")
            }
        })
    }

    const unAssignInvestigator = (complaintId: number, investigatorIndex: number) => {
        let investigator = assignedInvestigators[investigatorIndex]

        makeApiCall({
            url: `/api/internal-affairs/complaint/${complaintId}/unassign`,
            method: "POST",
            body: {
                assigneeCommunityId: investigator.assigneeCommunityId
            },
            onLoadedCallback: () => {
                setAlert("success", "Investigator un-assigned")
                assignedInvestigators.splice(investigatorIndex, 1)
            },
            onError: () => {
                setAlert("error", "Investigated failed to update due to a issue")
            }
        })
    }

    function Investigator(props: investigator) {
        let investigator = assignedInvestigators[props.investigatorIndex]

        return <Stack spacing={1} direction="row" alignItems="center">
            {props.complaint.complaintPermissions.includes("assign") ? <HighlightOffOutlinedIcon color="error" fontSize="small" onClick={() => {
                if (props.complaint.complaintPermissions.includes("assign") && props.complaint.status !== "final_reported") {
                    unAssignInvestigator(props.complaint.id, props.investigatorIndex)
                }
            }}/> : <></>}
            <Typography variant="subtitle1"> {investigator.nick}</Typography>
        </Stack>
    }

    let invalidInvestigatorIds: Array<string> = assignedInvestigators.map(investigator => {
        return investigator.assigneeCommunityId
    })
    invalidInvestigatorIds.push(props.complaint.defendantCommunityId)
    invalidInvestigatorIds.push(props.complaint.creatorCommunityId)

    return <Stack spacing={1}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                <Typography sx={{pt:2}} variant="h6" gutterBottom>Content and Evidence</Typography>
                <Paper variant={"outlined"} style={{
                    padding: "16px",
                }}>
                    <EditorContent content={props.complaint.content}/>
                </Paper>
                {statementView}
                <Typography sx={{pt:2}} variant="h6" gutterBottom>Complaint Conversation</Typography>
                <Stack sx={{mt:2}} spacing={1}>
                    {
                        complaintMessages?.map((message, idx) => {
                            return <MessageBoxWithUserDetails key={idx}
                                authorCommunityId={message.authorCommunityId}
                                authorNick={message.authorNick}
                                authorRpName={message.authorRpName}
                                authorRankDisplayName={message.authorRankDisplayName}
                                authorRankColor={message.authorRankColor}
                                date={message.date}
                                content={message.content}
                            />
                        })
                    }
                    <Paper variant={"outlined"} style={{
                        padding: "16px"
                    }}>
                        {InternalAffairsComplaintStatus.get(complaintStatus ?? "closed")!!.blockReplies && !props.user.permissions.includes("internalAffairs:extendedComplaintActions") ?
                            <>
                                <Typography>You do not have permission to reply to this complaint</Typography>
                            </> : <>
                                 <TextEditor ref={editorRef} initialContent={initialContent}/>
                                 <Button color="info" onClick={() => {
                                     if (editorRef.current) {
                                         editorRef.current.getEditorState().read(() => {
                                             let content = $generateHtmlFromNodes(editorRef.current!)

                                             editorRef.current?.update(() => {
                                                 $getRoot().clear();
                                             })

                                             makeApiCall({
                                                 url: `/api/internal-affairs/complaint/${props.complaint.id}/message`,
                                                 method: "POST",
                                                 body: {
                                                     messageHtml: content
                                                 },
                                                 onLoadedCallback: () => {
                                                     setAlert("success", "Message sent")
                                                     setComplaintMessages([...complaintMessages, {
                                                         complaintId: props.complaint.id,
                                                         authorCommunityId: props.user.communityId,
                                                         content: content,
                                                         date: new Date().toString(),
                                                         authorNick: props.user.nick,
                                                         authorRpName: props.user.rpName,
                                                         authorRankColor: props.user.rank?.color,
                                                         authorRankDisplayName: props.user.rank?.displayName
                                                     }]);
                                                 },
                                                 onError: () => {
                                                     setAlert("error", "Message failed to send due to a issue")
                                                 }
                                             })
                                         })
                                     }
                                 }}>Send Message</Button>
                            </>
                        }
                    </Paper>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                <Typography sx={{pt:2}} variant="h6" gutterBottom>Complaint Management</Typography>
                <Paper variant={"outlined"} style={{
                    padding: "16px"
                }}>
                    <Stack spacing={1}>
                        <Typography variant="subtitle1">
                            Defendant - <Link underline="none" component={RouterLink} to={`/user/${props.complaint.defendantCommunityId}`}>
                                {props.complaint.defendantName}
                            </Link>
                        </Typography>
                        <Divider/>
                        <Typography variant="subtitle1">
                            Creator - <Link underline="none" component={RouterLink} to={`/user/${props.complaint.creatorCommunityId}`}>
                                {props.complaint.creatorName}
                            </Link>
                        </Typography>
                        <Divider/>
                        <Typography variant="subtitle1">Created
                            - {formatTimestamp(props.complaint.creationDate)}</Typography>
                        <Divider/>
                        <Typography variant="subtitle1">Outcome - {InternalAffairsComplaintOutcome.get(props.complaint.outcome ?? "pending")?.displayName}</Typography>
                        <Divider/>
                        <Typography variant="subtitle1">Assigned Investigators</Typography>
                        {
                            assignedInvestigators?.map((_, idx) => {
                                return <Investigator key={_.assigneeCommunityId} investigatorIndex={idx} complaint={props.complaint}/>
                            })
                        }
                        <Divider/>
                        <Typography variant="subtitle1">Status</Typography>
                        <Select
                            id="enter-status"
                            value={complaintStatus}
                            disabled={!props.complaint.complaintPermissions.includes("edit_status") || complaintStatus === "final_reported" || !InternalAffairsComplaintStatus.get(complaintStatus ?? "closed")?.action}
                            onChange={(event) => {
                                updateStatus(props.complaint.id, event.target.value as string)
                            }}>
                            {
                                internalAffairsComplaintStatus.map(status => {
                                    return <MenuItem disabled={
                                        !status.action ||
                                        !props.user.permissions.includes(status?.neededPermission ?? "internalAffairs:viewComplaint")
                                    } key={status.key} value={status.key}>{status.displayName}</MenuItem>
                                })
                            }
                        </Select>
                        <Divider/>
                        <Typography variant="subtitle1">Classification</Typography>
                        <Select
                            id="enter-class"
                            value={complaintClassification}
                            disabled={!props.complaint.complaintPermissions.includes("classify")}
                            onChange={(event) => {
                                updateClassification(props.complaint.id, event.target.value as string)
                            }}>
                            {
                                internalAffairsComplaintClassification.map(classification => {
                                    return <MenuItem key={classification.key} value={classification.key}>{classification.displayName}</MenuItem>
                                })
                            }
                        </Select>
                        <Divider/>
                        <Typography variant="subtitle1">Assign Investigator</Typography>
                        <Select
                            id="enter-inves"
                            value={selectedInvestigatorIndex}
                            disabled={!props.complaint.complaintPermissions.includes("assign")}
                            onChange={(event) => {
                                setSelectedInvestigatorIndex(event.target.value as number)
                            }}>
                            {
                                props.investigators.filter(investigator => {
                                    return !invalidInvestigatorIds.includes(investigator.communityId);
                                }).map((investigator, idx) => {
                                    return <MenuItem key={investigator.communityId}
                                                     value={idx}>{investigator.nick}</MenuItem>
                                })
                            }
                        </Select>
                        <Button color="info" disabled={!props.complaint.complaintPermissions.includes("assign")} onClick={() => {
                            assignInvestigator(props.complaint.id, selectedInvestigatorIndex ?? -1)
                        }}>Assign</Button>
                        <Divider/>
                        {finalReportButton}
                        <Button component={RouterLink} to={`/complaints`} color="warning">Return</Button>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Stack>
}

export const InternalAffairsComplaintPage = () => {
    const params = useParams()
    const complaintData = useApiCall<InternalAffairsComplaintResponse>({
        url: `/api/internal-affairs/complaint/${params.id}`
    })

    const complaintMessageData = useApiCall<InternalAffairsComplaintMessageResponse[]>({
        url: `/api/internal-affairs/complaint/${params.id}/message`
    })

    const investigatorData = useApiCall<InternalAffairsComplaintInvestigatorResponse[]>({
        url: `/api/internal-affairs/investigators`
    })

    const userData = useUser()

    const user = userData.user

    const userDivisionRole = useApiCall<DivisionRoles>({
        url: `/api/user/${user?.communityId}/division-role/internal_affairs`
    })

    let pageContent

    if (complaintData.isError) {
        pageContent =
            <Alert severity="error">You cannot access this complaint, if this is a mistake contact the Professional
                Standards Department</Alert>
    } else if (complaintData.data && complaintMessageData.data && investigatorData.data && user) {
        pageContent =
            <Complaint userIARoleDisplayName={userDivisionRole.data?.displayName ?? ''}
                       user={user} complaint={complaintData.data} complaintMessages={complaintMessageData.data}
                       investigators={investigatorData.data} complaintApiCall={complaintData}/>
    }

    return (
        <LoadingContent isLoading={
            complaintData.isLoading ||
            complaintMessageData.isLoading ||
            investigatorData.isLoading ||
            userDivisionRole.isLoading
        }>
            {pageContent}
        </LoadingContent>
    )
}