import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {clamp, parseIntOrNull} from "../utils";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {UserAssessmentResponse} from "../data/UserAssessmentResponse";
import {Alert, Button, Grid, Link, Paper, Snackbar, Stack} from "@mui/material";
import {UserAssessmentQuestion} from "../components/assessment/UserAssessmentQuestion";
import {EditorContent} from "../components/editor/EditorContent";
import {QuestionList} from "../components/assessment/QuestionList";
import {UserAssessmentQuestionResponse} from "../data/UserAssessmentQuestionResponse";
import {AlertType} from "../data/AlertType";
import {LoadingContent} from "../components/LoadingContent";
import {UserAssessmentQuestionInfoResponse} from "../data/UserAssessmentQuestionInfoResponse";
import {UserAssessmentFeedbackResponse} from "../data/UserAssessmentFeedbackResponse";
import useUser from "../hooks/useUser";

export const GradeAssessmentPage = () => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [questionNumber, _setQuestionNumber] = useState(parseIntOrNull(searchParams.get("question") ?? "") ?? undefined)
    const userAssessmentData = useApiCall<UserAssessmentResponse>({
        url: `/api/careers/grade/${params.id}`
    })

    const setQuestionNumber = (questionNumber?: number) => {
        if (questionNumber === undefined) {
            searchParams.delete("question")
        } else {
            searchParams.set("question", questionNumber.toString())
        }
        _setQuestionNumber(questionNumber)
        setSearchParams(searchParams)
    }

    return <LoadingContent isLoading={userAssessmentData.isLoading || !userAssessmentData.data}>
        <GradeAssessmentPageContent assessment={userAssessmentData.data!} questionNumber={questionNumber}
                                    setQuestionNumber={setQuestionNumber}/>
    </LoadingContent>
}

interface GradeAssessmentPageProps {
    assessment: UserAssessmentResponse
    questionNumber?: number
    setQuestionNumber: (questionNumber?: number) => void
}

const GradeAssessmentPageContent = (props: GradeAssessmentPageProps) => {
    const user = useUser()
    const [questionInfo, setQuestionInfo] = useState(props.assessment.questions)
    const [question, setQuestion] = useState<UserAssessmentQuestionResponse>()
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const allGraded = questionInfo.every(q => q.awardedPoints !== null)
    const navigate = useNavigate()

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    useEffect(() => {
        if (props.questionNumber !== undefined) {
            const questionId = props.assessment.questions[(props.questionNumber ?? 1) - 1]?.id
            if (questionId !== undefined) {
                setApiCall(makeApiCall({
                    url: `/api/careers/grade/${props.assessment.id}/question/${questionId}`,
                    onLoadedCallback: (data: UserAssessmentQuestionResponse) => setQuestion(data),
                    onError: () => {
                    }
                }))
            }
        } else {
            setQuestion(undefined)
        }

    }, [props.assessment, props.questionNumber, setApiCall, setQuestion]);

    const incrementQuestionNumber = (x: number) => {
        props.setQuestionNumber(clamp((question?.order ?? 0) + x, 1, props.assessment.questions.length))
    }

    const doUpdateGrade = useCallback((question: UserAssessmentQuestionResponse, points: number, onSuccess: () => void) => {
        setApiCall(makeApiCall({
            url: `/api/careers/grade/${props.assessment.id}/question/${question.id}`,
            method: "PATCH",
            body: {
                points: points
            },
            onLoadedCallback: (data: UserAssessmentQuestionInfoResponse) => {
                onSuccess()
                setAlert("success", `Updated grade for Question ${question.order}`)
                const newQuestionInfo = questionInfo.map(_ => _)
                newQuestionInfo[data.order - 1] = data
                setQuestionInfo(newQuestionInfo)
            },
            onError: () => {
                setAlert("error", `Failed to update grade for Question ${question.order}`)
            }
        }))
    }, [props.assessment.id, questionInfo, setAlert])

    const doSubmitFeedback = useCallback((
        question: UserAssessmentQuestionResponse,
        feedback: string,
        isPrivate: Boolean,
        onSuccess: (response: UserAssessmentFeedbackResponse) => void
    ) => {
        setApiCall(makeApiCall({
            url: `/api/careers/grade/${props.assessment.id}/question/${question.id}/feedback`,
            method: "POST",
            body: {
                isPrivate: isPrivate,
                feedback: feedback,
            },
            onLoadedCallback: (data: UserAssessmentFeedbackResponse) => {
                setAlert("success", `Submitted feedback for Question ${question.order}`)
                onSuccess(data)
            },
            onError: () => {
                setAlert("error", `Failed to submit feedback for Question ${question.order}`)
            }
        }))
    }, [props.assessment.id, setAlert])

    const doDeleteFeedback = useCallback((question: UserAssessmentQuestionResponse, id: number, onSuccess: () => void) => {
        setApiCall(makeApiCall({
            url: `/api/careers/grade/${props.assessment.id}/question/${question.id}/feedback`,
            method: "DELETE",
            body: {
                id: id,
            },
            onLoadedCallback: () => {
                setAlert("success", `Deleted feedback for Question ${question.order}`)
                onSuccess()
            },
            onError: () => {
                setAlert("error", `Failed to delete feedback for Question ${question.order}`)
            }
        }))
    }, [props.assessment.id, setAlert])

    return <Paper variant={"outlined"} sx={{padding: "16px", marginTop: "16px"}}>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
                <Stack spacing={2}>
                    <Link component="button" variant="h4" underline="hover" color="inherit"
                          sx={{textAlign: "inherit"}} onClick={() => {
                        props.setQuestionNumber(undefined)
                    }}>{props.assessment.title}</Link>
                    {question !== undefined ? <>
                            <UserAssessmentQuestion key={question.order} assessment={props.assessment} question={question}
                                                    doUpdateGrade={doUpdateGrade} doSubmitFeedback={doSubmitFeedback}
                                                    doDeleteFeedback={doDeleteFeedback} variant="grader"
                                                    canDeleteFeedback={user.hasPermissions("assessment:deleteFeedback")}/>
                            <Stack direction="row" spacing={2}>
                                <Button disabled={question.order <= 1} variant="contained"
                                        onClick={() => incrementQuestionNumber(-1)}>Previous
                                    Question</Button>
                                <Button disabled={question.order >= questionInfo.length} variant="contained"
                                        onClick={() => incrementQuestionNumber(1)}>Next
                                    Question</Button>
                            </Stack>
                        </> :
                        <>
                            <EditorContent content={props.assessment.description}/>
                            <Button variant="contained"
                                    onClick={() => props.setQuestionNumber(questionInfo[0].order)}>Continue</Button>
                        </>
                    }

                </Stack>
            </Grid>
            <Grid item lg={3} xs={12}>
                <Paper variant="outlined" sx={{padding: "16px"}}>
                    <Stack spacing={2}>
                        <QuestionList questions={questionInfo} selectedQuestion={(question?.id)}
                                      submitted={props.assessment.submitted}
                                      onClick={question => props.setQuestionNumber(question.order)}/>
                        <Button variant="contained" disabled={props.assessment.passed !== null} color={allGraded ? "success" : "info"}
                                onClick={() => {
                                    setApiCall(makeApiCall({
                                        url: `/api/careers/marking/${props.assessment.id}/submit`,
                                        method: "POST",
                                        body: {},
                                        onLoadedCallback: () => {
                                            navigate("/careers")
                                            setAlert("success", `Submitted to user`)
                                        },
                                        onError: () => {
                                            setAlert("error", `Action Failed`)
                                        }
                                    }))
                                }}>
                            Finish Grading
                        </Button>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Paper>
}