import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {Alert, DialogContent, Link, Snackbar} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import {InternalAffairsStatementResponse} from "../../data/internal-affairs/InternalAffairsStatementResponse";
import {Link as RouterLink} from "react-router-dom";
import {LexicalEditor} from "lexical";
import {TextEditor} from "../../components/editor/TextEditor";
import {AlertType} from "../../data/AlertType";
import {EditorContent} from "../../components/editor/EditorContent";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {$generateHtmlFromNodes} from '@lexical/html';
import {
    InternalAffairsStatementQuestionResponse
} from "../../data/internal-affairs/InternalAffairsStatementQuestionResponse";

interface InternalAffairsStatementViewFormProps {
    statement: InternalAffairsStatementResponse
}

interface editorWrapperProps {
    response: InternalAffairsStatementQuestionResponse
    generalResponses: InternalAffairsStatementQuestionResponse[]
}

export default function InternalAffairsStatementSubmitForm(props: InternalAffairsStatementViewFormProps) {
    const [open, setOpen] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [questionResponses, setQuestionResponses] = useState(props.statement.questions)
    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const handleClose = () => {
        setOpen(false);
    };

    let missingResponseAlert = false
    questionResponses?.forEach(q => {
        if (q.response === null) {
            missingResponseAlert = true
        }
    })

    function EditorWrapper(props: editorWrapperProps) {
        const editorRef = useRef<LexicalEditor>(null)
        const loadedTextEditor = <TextEditor initialContent={props.response.response} ref={editorRef}/>
        return <>
            <EditorContent content={props.response.question} />
            {props.response.response === null ? <>
                {loadedTextEditor}
                <Button color={"success"} onClick={() => {
                    if (editorRef.current) {
                        editorRef.current.getEditorState().read(() => {
                            let content = $generateHtmlFromNodes(editorRef.current!)
                            setQuestionResponses(props.generalResponses.map(q => {
                                if (q.id === props.response.id) {
                                    q.response = content
                                    return q
                                } else {
                                    return q
                                }
                            }))
                        })
                    }
                    setAlert("success", "Response saved")
                }}>Save</Button>
            </> : <EditorContent content={props.response.response!!}/>}
        </>
    }

    return (
        <React.Fragment>
            <Button onClick={() => {
                setOpen(true)
            }}>Submit Statement</Button>
            <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
                {missingResponseAlert ? <Alert severity="error">You have not responded to all questions</Alert> : <></>}
                <DialogContent sx={{overflow: "hidden"}}>
                    <DialogContentText>
                        Provider
                    </DialogContentText>
                    <DialogContentText>
                        <Link underline="none"
                              component={RouterLink}
                              to={`/user/${props.statement.providerCommunityId}`}
                        > {props.statement.providerRpName} ({props.statement.providerNick}) </Link>
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <EditorContent content={props.statement.request}/>
                </DialogContent>
                <DialogContent>
                    {questionResponses && questionResponses.map((q) => {
                        return <EditorWrapper key={q.id} generalResponses={questionResponses} response={q}/>
                    })}
                </DialogContent>
                <DialogContent sx={{overflow: "hidden"}}>
                    <Button color={"info"} onClick={() => handleClose()}>Close</Button>
                    <Button color={"success"} disabled={missingResponseAlert} onClick={() => {
                        makeApiCall({
                            url: `/api/internal-affairs/statement`,
                            method: "POST",
                            body: {
                                statementId: props.statement.id,
                                questionResponses: questionResponses
                            },
                            onLoadedCallback: () => {
                                setAlert("success", "Statement sent")
                                handleClose()
                            },
                            onError: () => {
                                setAlert("error", "Statement failed to send")
                            }
                        })
                    }}>Submit Statement</Button>
                </DialogContent>
            </Dialog>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
