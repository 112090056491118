import React, {useRef, useState} from "react";
import {Button, Input, MenuItem, Paper, Select, Stack, Typography} from "@mui/material";
import {LexicalEditor} from "lexical";
import {TextEditor} from "../components/editor/TextEditor";
import useApiCall, {makeApiCall} from "../hooks/CancellableApiCall";
import {$generateHtmlFromNodes} from '@lexical/html';
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {Division} from "../data/Division";

export const HelpdeskNewTicketPage = () => {
    const params = useParams()
    const [division, setDivision] = useState(params.divisionIdentifier ?? "")
    const editorRef = useRef<LexicalEditor>(null)
    const navigate = useNavigate()
    const [title, setTitle] = useState("")

    const blacklistedDivisions = [
        "no_division",
        "office_of_operation_services",
        "complaint_committee"
    ]

    const allDivisionsData = useApiCall<Division[]>({
        url: "/api/division/list-divisions"
    })

    return (
        <Stack spacing={1}>
            <Typography variant={"h6"} gutterBottom>New Ticket</Typography>
            <Paper variant={"outlined"} style={{
                padding: "16px"
            }}>
                <Typography variant="subtitle1">Ticket Title</Typography>
                <Input multiline rows={1} sx={{width: 300}} placeholder="Title..."
                       onChange={(event) => setTitle(event.target.value)}/>
            </Paper>
            <Paper variant={"outlined"} style={{
                marginTop: "16px",
                padding: "16px"
            }}>
                <Typography variant="subtitle1">Ticket Division</Typography>
                <Select
                    id="select-division"
                    value={division}
                    onChange={(event) => {
                        setDivision(event.target.value as string)
                    }}
                    sx={{width: 300}}
                >
                    {
                        allDivisionsData.data?.filter(division => {
                            return !blacklistedDivisions.includes(division.identifier)
                        })?.map(division => {
                            return <MenuItem key={division.id}
                                             value={division.identifier}>{division.displayName}</MenuItem>
                        })
                    }
                </Select>
            </Paper>
            <Paper variant={"outlined"} style={{
                marginTop: "16px",
                padding: "16px"
            }}>
                <Typography variant="subtitle1">Ticket Details</Typography>
                <TextEditor ref={editorRef}/>
                <Button disabled={division === ""} color="success" onClick={() => {
                    if (editorRef.current) {
                        editorRef.current.getEditorState().read(() => {
                            makeApiCall({
                                url: `/api/helpdesk/`,
                                method: "POST",
                                body: {
                                    title: title,
                                    divisionIdentifier: division,
                                    initialContent: $generateHtmlFromNodes(editorRef.current!)
                                },
                                onLoadedCallback: () => {
                                    navigate("/helpdesk")
                                },
                                onError: () => {

                                }
                            })
                        })
                    }
                }}>Create Ticket</Button>
                <Button component={RouterLink} to={`/helpdesk`} color="warning">Return</Button>
            </Paper>
        </Stack>
    )
}