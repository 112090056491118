import React from "react"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import {styled} from '@mui/material/styles';
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {CareersResponse} from "../../data/CareersResponse";
import useUser from "../../hooks/useUser";
import {LoadingContent} from "../../components/LoadingContent";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {CareerRequirements} from "../../data/CareerRequirements";
import {ApplicationRequirement} from "./CareersPage";

interface ClosedCareersResponse {
    id: number,
    roleName: string,
    roleIdentifier: string,
    divisionName: string,
    divisionIdentifier: string,
    divisionBadge: string,
    description: string,
    state: string
    requirements: Array<CareerRequirements>
    currentAssessment: number
    lastAssessmentId: number
    rankOnPassDisplayName: string
    type: string
}

const ClosedApplication = (props: ClosedCareersResponse) => {
    const theme = useTheme()
    const navigate = useNavigate()
    let lastAssessmentButton = <></>

    if (props.lastAssessmentId != null) {
        lastAssessmentButton = <Button component={RouterLink}
                to={`/assessments/user/${props.lastAssessmentId}/review`} size={"small"} color="info"
                variant="text">Review last Stage</Button>
    }

    let applicationTitle
    if (props.type === "rank") {
        applicationTitle = props.rankOnPassDisplayName
    } else if (props.type === "division_role") {
        applicationTitle = props.roleName
    } else if (props.type === "taser") {
        applicationTitle = "Taser"
    } else {
        applicationTitle = "UNKNOWN APPLICATION TYPE"
    }

    const CareerBlock = styled('div')`
        height: 120px;
        display: flex;
        width:100%;
        cursor:default;
    `

    const TextBracket = styled('div')`
        margin-left:25px;
        display: flex;
        height:100%;
        width:100%;
        justify-content:center;
        flex-direction:column;
        align-content:center;
    `

    const [expand, setExpand] = React.useState(false);

    let buttonText = (expand ? "Read Less" : "Read More")
    const toggleAcordion = () => {
        setExpand((prev) => !prev);
    };

    return (
            <Paper variant={"outlined"}>
                <Accordion sx={{backgroundImage:"none", boxShadow:0}} expanded={expand}>
                    <AccordionSummary sx={{p:0}}>
                        <CareerBlock>
                            <TextBracket>
                                <Typography variant={"h5"} sx={{
                                    fontSize: {xs:"1.2rem", sm:"1.3rem", md:"1.5rem"}
                                }}>
                                    {applicationTitle}
                                    <Button onClick={toggleAcordion} sx={{ml:1, width:100}} size={"small"} variant="text">{buttonText}</Button>
                                </Typography>
                                <Typography sx={{pb:1}} color={theme.palette.text.secondary} variant={"body2"}>
                                    {props.divisionName}
                                </Typography>
                            </TextBracket>
                            {<Box
                                component={"img"}
                                src={props.divisionBadge}
                                sx={{
                                    width:"120px",
                                    height:"120px",
                                    padding:"15px",
                                    float:"right",
                                    display: {xs: "none", sm: "block"}
                                }}
                            />}
                        </CareerBlock>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails sx={{p:2}}>
                        <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                            {props.description}
                            <Divider sx={{pt:1, mb:1}} />
                        </Typography>
                        {
                            props.requirements?.map(requirement => {
                                return <ApplicationRequirement type={requirement.type} param={requirement.param} met={requirement.met} key={requirement.id}/>
                            })
                        }
                    </AccordionDetails>
                    <AccordionActions>
                        {lastAssessmentButton}
                        <Button component={RouterLink}
                                to={`/careers/overview/${props.id}/passed`} size={"small"} color="info"
                                variant="text">View Applicants</Button>
                        <Button size={"small"} color="success"
                                variant="text" onClick={() => {
                            makeApiCall({
                                url: `/api/careers/${props.id}/state`,
                                method: "PATCH",
                                body: {
                                    state: "locked"
                                },
                                onLoadedCallback: () => {
                                    navigate(`/careers/overview/${props.id}/passed`)
                                },
                                onError: () => {}
                            })
                        }}
                        >Reopen</Button>
                    </AccordionActions>
                </Accordion>
            </Paper>
    )
}

export const ClosedCareersPage = () => {
    const applications = useApiCall<Array<CareersResponse>>({
        url: `/api/careers/closed`,
    })

    const userData = useUser()

    const user = userData.user

    return (
        <LoadingContent isLoading={
            userData.isLoading || !user ||
            applications.isLoading ||  !applications
        }>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    {applications.data && applications.data.map(application => {
                            return <Grid sx={{paddingTop: 5, paddingRight: 5}} xs={12} sm={12} md={12} lg={6} xl={4} key={application.id}>
                                <ClosedApplication
                                    id={application.id}
                                    state={application.state}
                                    divisionName={application.divisionName}
                                    divisionIdentifier={application.divisionIdentifier}
                                    divisionBadge={application.divisionBadge}
                                    roleName={application.roleName}
                                    roleIdentifier={application.roleIdentifier}
                                    description={application.description}
                                    requirements={application.requirements}
                                    currentAssessment={application.currentAssessment}
                                    lastAssessmentId={application.lastAssessmentId}
                                    rankOnPassDisplayName={application.rankOnPassDisplayName}
                                    type={application.type}
                                />
                            </Grid>
                        }
                    )}
                </Grid>
            </Stack>
        </LoadingContent>
    )
}