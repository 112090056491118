import React, {useCallback, useState} from "react"
import {
    Alert,
    Button,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {RankResponse} from "../../data/RankResponse";
import {DivisionRoles} from "../../data/DivisionRoles";
import {LoadingContent} from "../../components/LoadingContent";
import {useNavigate, useParams} from "react-router-dom";
import {Division} from "../../data/Division";
import {ApplicationRequirements} from "./ApplicationRequierments";
import {allRequirements} from "./RankRequirements";
import {AlertType} from "../../data/AlertType";

interface NewApplicationPanelProps {
    ranks: RankResponse[]
    divisionRoles: DivisionRoles[]
    divisions: Division[]
}

export interface applicationRequirement {
    type: string,
    param: string | number
}

function NewApplicationPanel(props: NewApplicationPanelProps) {
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [newRoleApplicationRole, setNewRoleApplicationRole] = useState(-1)
    const [newRoleApplicationRank, setNewRoleApplicationRank] = useState<number>(-1)
    const [newRoleApplicationDescription, setNewRoleApplicationDescription] = useState("")
    const [newRoleApplicationMarkRequirement, setNewRoleApplicationMarkRequirement] = useState(-1)
    const [newRoleApplicationRankRequirements, setNewRoleApplicationRankRequirements] = useState<applicationRequirement[]>([])

    const navigate = useNavigate()

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const createNewApplication = (roleId: number, description: string, markerRoleId: number, requirements: applicationRequirement[] | null) => {
        makeApiCall({
            url: `/api/careers/new`,
            method: "POST",
            body: {
                divisionRoleId: roleId,
                description: description,
                markerRoleId: markerRoleId,
                requirements: requirements,
                type: "division_role",
                rankId: newRoleApplicationRank
            },
            onLoadedCallback: () => {
                setAlert("success", "Application created")
                navigate("/careers")
            },
            onError: () => {
                setAlert("error", "Application failed to create due to a issue")
                setNewRoleApplicationRankRequirements([])
            }
        })
    }

    return <>
        <Paper variant={"outlined"} style={{
                marginTop: "16px",
                padding: "16px"
            }}>
            <Stack spacing={1}>
                <Typography variant="h5">New Application</Typography>
                <Tooltip title="The role the application is for" arrow>
                    <InputLabel id="new-role-application-select-label">Role</InputLabel>
                </Tooltip>
                <Select id="new-role-application-select" value={newRoleApplicationRole} label="Role" onChange={(event) => {
                    setNewRoleApplicationRole(event.target.value as number)
                }}>
                    {props.divisionRoles.map((role) =>
                        <MenuItem key={role.id} value={role.roleId}>{role.displayName}</MenuItem>
                    )}
                </Select>
                <Tooltip title="The role that marks the application, everything below is optional" arrow>
                    <InputLabel id="new-role-mark-select-label">Marker Role</InputLabel>
                </Tooltip>
                <Select id="new-role-mark-select" value={newRoleApplicationMarkRequirement} label="Marker Role" onChange={(event) => {
                    setNewRoleApplicationMarkRequirement(event.target.value as number)
                }}>
                    {props.divisionRoles.map((role) =>
                        <MenuItem key={role.id} value={role.roleId}>{role.displayName}</MenuItem>
                    )}
                </Select>
                <Input multiline rows={4} placeholder="Application Description..."
                       onChange={(event) => setNewRoleApplicationDescription(event.target.value)}/>
                <InputLabel id="new-role-application-select-rank-label">Rank On Pass</InputLabel>
                <Select id="new-role-application-select-rank" value={newRoleApplicationRank}
                        label="Role" onChange={(event) => {
                    setNewRoleApplicationRank(event.target.value as number)
                }}>
                    {props.ranks.map((rank) =>
                        <MenuItem key={rank.rankId} value={rank.rankId}>{rank.displayName}</MenuItem>
                    )}
                </Select>
                <Button disabled={(newRoleApplicationRole === 0) || (newRoleApplicationMarkRequirement === -1)} variant="contained" onClick={() => {
                    createNewApplication(newRoleApplicationRole, newRoleApplicationDescription, newRoleApplicationMarkRequirement, newRoleApplicationRankRequirements)
                }}>
                    Create new application
                </Button>
            </Stack>
        </Paper>
        <ApplicationRequirements
            requirementTable={newRoleApplicationRankRequirements}
            ranks={props.ranks}
            divisionRoles={props.divisionRoles}
            divisions={props.divisions}
            setAlert={setAlert}
            requirementsToInclude = {allRequirements}
        />
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </>
}

export const NewApplicationPage = () => {
    const params = useParams()
    const loadRanks = useApiCall<RankResponse[]>({
        initialUrl: "/api/rank/subordinates"
    })

    const loadDivisionRoles = useApiCall<DivisionRoles[]>({
        initialUrl: `/api/division/roles/${params.divisionIdentifier?.replaceAll("-", "_")}`
    })

    const loadDivisions = useApiCall<Division[]>({
        url: `/api/division/list-divisions`
    })

    return <LoadingContent isLoading={
        loadRanks.isLoading || !loadRanks.data ||
        loadDivisionRoles.isLoading || !loadDivisionRoles.data ||
        loadDivisions.isLoading || !loadDivisions.data
    }>
        <NewApplicationPanel ranks={loadRanks.data!} divisionRoles={loadDivisionRoles.data!} divisions={loadDivisions.data!}/>
    </LoadingContent>
}