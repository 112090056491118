import React, {useState} from "react";
import {Alert, Button, FormControl, Input, Paper, Stack, Typography} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {makeApiCall} from "../hooks/CancellableApiCall";

export const RecordSearchPage = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchTarget, setSearchTarget] = useState(searchParams.get("target") ?? "")
    const [searchReason, setSearchReason] = useState("")
    const navigate = useNavigate()

    return <>
        <Stack spacing={2}>
            <Alert variant={"outlined"} severity="error">All record queries are logged by the Professional Standards Department. In order to carry out a record check you need a valid reason, that you have to provide in the query. For more information check Data Protection and Confidentiality policies</Alert>
            <Alert variant={"outlined"} severity="info">Identifier works for badge numbers, usernames, or communityIds</Alert>
        </Stack>
        <Typography sx={{pt:5}} variant="h6" gutterBottom>Record Access</Typography>
        <Paper variant={"outlined"} style={{
            padding: "20px"
        }}>
            <Stack spacing={1}>
                <FormControl>
                    <Input id="id" sx={{minWidth: 300, pt:2}} value={searchTarget} placeholder="Identifer..."
                           onChange={(event) => setSearchTarget(event.target.value)}/>
                </FormControl>
                <FormControl sx={{pt:3}}>
                    <Input id="reason" multiline rows={2} placeholder="Reason for search..."
                           onChange={(event) => setSearchReason(event.target.value)}/>
                </FormControl>
            </Stack>
            <Button sx={{mt:2, width:"100%"}} variant={"contained"} color="error" disabled={searchReason === "" || searchTarget === ""} onClick={() => {
                makeApiCall({
                    // Find a better way to do this for A1L later
                    url: `/api/records/search/${searchTarget}`,
                    method: "POST",
                    body: {
                        reason: searchReason,
                        target: searchTarget
                    },
                    onLoadedCallback: () => {
                        navigate(`/records/${searchTarget}`)
                    },
                    onError: () => {
                        navigate(`/records/${searchTarget}`)
                    }
                })
            }}>Search</Button>
        </Paper>
    </>
}