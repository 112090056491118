import React from "react"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import {styled} from '@mui/material/styles';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {CareersResponse} from "../../data/CareersResponse";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import useUser from "../../hooks/useUser";
import {LoadingContent} from "../../components/LoadingContent";
import {Division} from "../../data/Division";
import {NewApplicationDivisionSelectionForm} from "./NewApplicationDivisionSelectionForm";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {ApplicationRequirements} from "../../data/applications/ApplicationRequirements";
import ZoomInIcon from '@mui/icons-material/ZoomIn';

interface applicationRequirementProps {
    type: string,
    param: string,
    met: boolean | undefined
}

export const ApplicationRequirement = (props: applicationRequirementProps) => {
    let requirement
    if (props.met) {
        requirement = <Stack spacing={1} direction="row" alignItems="center">
            <CheckCircleOutlinedIcon color="success" fontSize="small"/>
            <Typography variant="subtitle1"> {ApplicationRequirements.get(props.type)?.displayName}: {props.param}</Typography>
        </Stack>
    } else if (!props.met) {
        requirement = <Stack spacing={1} direction="row" alignItems="center">
            <ErrorOutlineOutlinedIcon color="error" fontSize="small"/>
            <Typography variant="subtitle1"> {ApplicationRequirements.get(props.type)?.displayName}: {props.param}</Typography>
        </Stack>
    } else {
        requirement = <Stack spacing={1} direction="row" alignItems="center">
            <QuestionMarkIcon color="warning" fontSize="small"/>
            <Typography variant="subtitle1"> {ApplicationRequirements.get(props.type)?.displayName}: {props.param}</Typography>
        </Stack>
    }

    return requirement
}

const Application = (props: CareersResponse) => {
    const theme = useTheme()
    const navigate = useNavigate()
    let applicationBanner = <></>;
    let applicationButton = <></>;
    let lastAssessmentButton = <></>
    let managementButtons = <></>
    let meetsRequirements = true

    props.requirements?.forEach(r => {
        if (!r.met) {
            meetsRequirements = false
        }
    })

    if (props.canEdit) {
        let applicationStateButton = <></>
        if (props.state === "open") {
            applicationStateButton = <Button component={RouterLink}
                to={`/assessments/user/${props.id}/take`} size={"small"} color="warning"
                variant="text" onClick={() => {
                makeApiCall({
                    url: `/api/careers/${props.id}/state`,
                    method: "PATCH",
                    body: {
                        state: "locked"
                    },
                    onLoadedCallback: () => {
                        navigate(`/careers/overview/${props.id}/passed`)
                    },
                    onError: () => {}
                })
            }}
            >Lock Application</Button>
        } else if (props.state === "locked") {
            applicationStateButton = <Button component={RouterLink}
                to={`/assessments/user/${props.id}/take`} size={"small"} color="success"
                variant="text" onClick={() => {
                makeApiCall({
                        url: `/api/careers/${props.id}/state`,
                        method: "PATCH",
                        body: {
                            state: "open"
                        },
                        onLoadedCallback: () => {
                            navigate(`/careers/overview/${props.id}/passed`)
                        },
                        onError: () => {}
                    })
                }}
            >Open Application</Button>
        }
        managementButtons = <>
            <Button component={RouterLink}
                    to={`/edit-application-requirements/${props.type.replaceAll("_", "-")}/${props.divisionIdentifier}/${props.id}`} sx={{width: "full"}} color="info"
            >Edit Application</Button>
            {applicationStateButton}
            <Button component={RouterLink}
                    to={`/assessments/user/${props.id}/take`} size={"small"} color="error"
                    variant="contained" onClick={() => {
                makeApiCall({
                    url: `/api/careers/${props.id}/state`,
                    method: "PATCH",
                    body: {
                        state: "closed"
                    },
                    onLoadedCallback: () => {
                        navigate(`/closed-careers`)
                    },
                    onError: () => {}
                })
            }}
            >Close</Button>
        </>
    }

    if (props.state === "open") {
        if (props.rankOnPassDisplayName != null && props.type !== "rank") {
            applicationBanner = <Alert sx={{mb:2}} variant="outlined" severity="info">You will be given the rank of {props.rankOnPassDisplayName} upon completion</Alert>
        }
        if (props.currentAssessment != null && meetsRequirements) {
            applicationButton = <>
                <Button component={RouterLink}
                        to={`/assessments/user/${props.id}/take`} size={"small"} sx={{width: 100}} color="info"
                        variant="outlined">Continue</Button>
                <Button color="error" size={"small"} sx={{ml:1}} variant="text" onClick={() => {
                    makeApiCall({
                        url: `/api/careers/${props.currentAssessment}/withdraw`,
                        method: "POST",
                        body: {},
                        onLoadedCallback: () => {
                            navigate(`/login`)
                        },
                        onError: () => {}
                    })
                }}>Withdraw</Button>
            </>
        } else if (props.tooHighToApplyToRole && !props.canMark) {
            applicationButton = <Button disabled sx={{width:50}} size={"small"} color="error" variant="outlined">Apply</Button>
        } else if (props.canMark) {
            applicationButton = <Button component={RouterLink}
                                        to={`/careers/overview/${props.id}/passed`} size={"small"} color="info"
                                        variant="outlined">Manage Applicants</Button>
        } else {
            applicationButton = <Button disabled={!meetsRequirements} sx={{ width:50 }} size={"small"} color="success" variant="outlined" onClick={() => {
                makeApiCall({
                    url: `/api/careers/${props.id}/apply`,
                    method: "POST",
                    body: {},
                    onLoadedCallback: () => {
                        navigate(`/assessments/user/${props.id}/take`)
                    },
                    onError: () => {
                        //setAlert("error", "Failed to open application")
                    }
                })
            }}>Apply</Button>
        }
    } else if (props.state === "locked") {
        applicationBanner = <Alert sx={{mb:2}} variant="outlined" severity="error">This application is not currently accepting applicants</Alert>
        if (props.canMark) {
            applicationButton = <Button component={RouterLink}
                                        to={`/careers/overview/${props.id}/passed`} size={"small"} color="info"
                                        variant="outlined">Manage Applicants</Button>
        }
    } else if (props.state === "editing") {
        applicationBanner = <Alert sx={{mb:2}} variant={"outlined"} severity="info">This application is being written, and is hidden</Alert>
        applicationButton = <Button component={RouterLink}
                                    to={`/edit-application/${props.id}`} size={"small"}  variant="outlined" color="info">Continue Editing Assessments</Button>
    }

    if (props.lastAssessmentId != null) {
        lastAssessmentButton = <Button component={RouterLink}
                to={`/assessments/user/${props.lastAssessmentId}/review`} size={"small"} color="info"
                variant="text">Review last Stage</Button>
    }

    let applicationTitle
    if (props.type === "rank") {
        applicationTitle = props.rankOnPassDisplayName
    } else if (props.type === "division_role") {
        applicationTitle = props.roleName
    } else if (props.type === "taser") {
        applicationTitle = "Taser"
    } else {
        applicationTitle = "UNKNOWN APPLICATION TYPE"
    }

    const CareerBlock = styled('div')`
        height: 120px;
        display: flex;
        width:100%;
        cursor:default;
    `

    const TextBracket = styled('div')`
        margin-left:25px;
        display: flex;
        height:100%;
        width:100%;
        justify-content:center;
        flex-direction:column;
        align-content:center;
    `

    const [expand, setExpand] = React.useState(false);

    const toggleAcordion = () => {
        setExpand((prev) => !prev);
    };

    return (
            <Paper variant={"outlined"}>
                <Accordion sx={{backgroundImage:"none", boxShadow:0}} expanded={expand}>
                    <AccordionSummary sx={{p:0}}>
                        <CareerBlock>
                            <TextBracket>
                                <Typography variant={"h5"} sx={{
                                    fontSize: {xs:"1.2rem", sm:"1.3rem", md:"1.5rem"}
                                }}>
                                    {applicationTitle}
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={toggleAcordion}
                                    >
                                        <ZoomInIcon/>
                                    </IconButton>
                                </Typography>
                                <Typography sx={{pb:1}} color={theme.palette.text.secondary} variant={"body2"}>
                                    {props.divisionName}
                                </Typography>
                                <Stack direction={"row"}>
                                    {applicationButton}
                                </Stack>
                            </TextBracket>
                            {<Box
                                component={"img"}
                                src={props.divisionBadge}
                                sx={{
                                    width:"120px",
                                    height:"120px",
                                    padding:"15px",
                                    float:"right",
                                    display: {xs: "none", sm: "block"}
                                }}
                            />}
                        </CareerBlock>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails sx={{p:2}}>
                        {applicationBanner}
                        <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                            {props.description}
                            <Divider sx={{pt:1, mb:1}} />
                        </Typography>
                        {
                            props.requirements?.map(requirement => {
                                if (!requirement.met && props.state === "open" && !props.canMark) {
                                    applicationButton = <Button disabled sx={{ width: "full" }} color="error" variant="contained">Requirements not met</Button>
                                }
                                return <ApplicationRequirement type={requirement.type} param={requirement.param} met={requirement.met} key={requirement.id}/>
                            })
                        }
                    </AccordionDetails>
                    <AccordionActions>
                        {lastAssessmentButton}
                        {managementButtons}
                    </AccordionActions>
                </Accordion>
            </Paper>
    )
}

export const CareersPage = () => {
    let accessibleDivisions: Division[] = []
    const applications = useApiCall<Array<CareersResponse>>({
        url: `/api/careers/`,
    })

    const divisions = useApiCall<Division[]>({
        url: `/api/division/list-divisions`,
    })

    const userData = useUser()

    const user = userData.user

    let newCareerButton = <></>
    divisions.data?.forEach(division => {
        if (userData.hasPermissions(`${division.identifier}-careers:create`)) {
            accessibleDivisions = [...accessibleDivisions, division]
        }
    })
    if (accessibleDivisions.length > 0) {
        newCareerButton = <>
            <NewApplicationDivisionSelectionForm divisions={accessibleDivisions} />
            <Button sx={{width: 200}} color="info" variant="contained" component={RouterLink}
                    to={`/closed-careers`}>Closed Applications</Button>
        </>
    }

    let pageContent
    if (user?.blacklisted && user?.rank?.identifier === "civilian") {
        pageContent = <Stack spacing={2}>
            <Alert severity="error">You are blacklisted from the Paralake Police Department's Application Portal</Alert>
        </Stack>
    } else if (user?.suspended) {
        pageContent = <Stack spacing={2}>
            <Alert severity="error">You are blacklisted from the Paralake Police Department's Application Portal</Alert>
            <Alert severity="warning">This blacklist is temporary due to being suspended, this will be removed by Internal Affairs Command when the complaint against you is reviewed</Alert>
        </Stack>
    } else if (user?.blacklisted && user?.rank?.identifier === "probationary_officer") {
            pageContent = <Stack spacing={2}>
                <Alert severity="error">You are blacklisted from the Paralake Police Department's Application Portal</Alert>
                <Alert severity="warning">You are a Probationary Officer so your application blacklist will be lifted when you pass your Probationary Officer review</Alert>
            </Stack>
    } else {
        pageContent = <Stack spacing={2}>
            {newCareerButton}
            <Grid container spacing={2}>
                {applications.data && applications.data.map(application => {
                        return <Grid sx={{paddingTop: 5, paddingRight: 5}} xs={12} sm={12} md={12} lg={6} xl={4} key={application.id}>
                            <Application
                                id={application.id}
                                state={application.state}
                                divisionName={application.divisionName}
                                divisionIdentifier={application.divisionIdentifier}
                                divisionBadge={application.divisionBadge}
                                roleName={application.roleName}
                                roleIdentifier={application.roleIdentifier}
                                description={application.description}
                                requirements={application.requirements}
                                currentAssessment={application.currentAssessment}
                                lastAssessmentId={application.lastAssessmentId}
                                tooHighToApplyToRole={application.tooHighToApplyToRole}
                                canMark={application.canMark}
                                canEdit={application.canEdit}
                                rankOnPassDisplayName={application.rankOnPassDisplayName}
                                type={application.type}
                            />
                        </Grid>
                    }
                )}
            </Grid>
        </Stack>
    }

    return (
        <LoadingContent isLoading={
            userData.isLoading || !user ||
            applications.isLoading ||  !applications ||
            divisions.isLoading || !divisions
        }>
            {pageContent}
        </LoadingContent>
    )
}