import {Grid, Typography, ListItem, ListItemIcon, ListItemText, useTheme, Divider, Paper, Box} from "@mui/material";
import {BadgeOutlined, LocalPoliceOutlined, CategoryOutlined, Grid3x3Outlined, RemoveRedEyeOutlined, HandshakeOutlined} from "@mui/icons-material";
import {Award} from "../Award";
import {ProfileResponse} from "../../data/ProfileResponse";
import {formatTimestamp} from "../../utils";

interface EmployeeDetailsProps {
    data: ProfileResponse
}

export function EmployeeDetails(props: EmployeeDetailsProps) {

    const theme = useTheme()

    let taserRole = <></>
    let reservedSlotRole = <></>
    let lastSeen = "Never"
    if (props.data.lastSeen) {
        lastSeen = formatTimestamp(props.data.lastSeen)
    }

    if (props.data.reservedSlot) {
        reservedSlotRole = <Typography color={theme.palette.text.secondary}>
            Reserved Slot
        </Typography>
    }

    if (props.data.taser) {
        taserRole = <Typography color={theme.palette.text.secondary}>
            Taser Officer
        </Typography>
    }

    let pronouns = (props.data.pronouns ? props.data.pronouns : "Not Specified")

    return <Grid container justifyContent={"space-between"} spacing={2}>
        <Grid item xs={12}>

            <Typography variant="h6">
                    Details:
            </Typography>

            <Grid container sx={{p:2}} spacing={2}>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={2}>
                    <ListItem>
                        <ListItemIcon>
                            <BadgeOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Name" secondary={props.data.rpName} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={2}>
                <ListItem>
                    <ListItemIcon>
                        <LocalPoliceOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Badge Number" secondary={props.data.badgeNumber} />
                </ListItem>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={2}>
                <ListItem>
                    <ListItemIcon>
                        <CategoryOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Division" secondary={props.data.roles[0]?.displayName} />
                </ListItem>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={2}>
                <ListItem>
                    <ListItemIcon>
                        <Grid3x3Outlined />
                    </ListItemIcon>
                    <ListItemText primary="SteamID" secondary={props.data.communityId} />
                </ListItem>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={2}>
                <ListItem>
                    <ListItemIcon>
                        <RemoveRedEyeOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Last Seen" secondary={lastSeen} />
                </ListItem>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={2}>
                <ListItem>
                    <ListItemIcon>
                        <HandshakeOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Pronouns" secondary={pronouns} />
                </ListItem>
                </Grid>


            </Grid>

            {/*<Divider/>*/}
        </Grid>

        <Grid item xs={12} md={6}>
            <Paper variant={"outlined"} sx={{padding: '15px'}}>

                <Typography variant="h6">
                    Specialist Roles:
                </Typography>

                <Grid container sx={{p:2}} spacing={2}>

                            {
                                props.data.roles.map((role, index) => {
                                    if (index === 0) {
                                        return null;
                                    }

                                    return (
                                        <Grid key={role.identifier} item xs={12} md={6}>
                                        <Typography color={theme.palette.text.secondary} key={role.id.toString()}>
                                            {role.displayName}
                                        </Typography>
                                        </Grid>
                                    );
                                })
                            }
                    <Grid item xs={12} md={6}>
                        {taserRole}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {reservedSlotRole}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
            <Divider />
            <Box sx={{p:2}}>
                <Typography variant="h6">
                    Awards:
                </Typography>
                <Grid container sx={{p:2}} spacing={2}>
                    {props.data.awards.map(award => <Grid item key={award.id}><Award award={award}/></Grid>)}
                </Grid>
            </Box>
        </Grid>
    </Grid>
}