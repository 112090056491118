import React, {useEffect} from 'react';
import {
    Box,
    Collapse,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    useMediaQuery,
    useTheme
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import ChecklistRtlOutlinedIcon from '@mui/icons-material/ChecklistRtlOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import QuizIcon from '@mui/icons-material/Quiz';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {AccountResponse, userHasPermissions} from '../data/AccountResponse';
import {Link, useLocation} from "react-router-dom";
import {ExpandLess, ExpandMore, SettingsSuggestOutlined} from "@mui/icons-material";
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import ListIcon from '@mui/icons-material/List';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIconOutlined from '@mui/icons-material/PeopleOutlined';
import EmailIcon from '@mui/icons-material/Email';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import {PoliceAvatar} from "./PoliceAvatar";
import {RankChip} from "./RankChip";
import {useNavBarState} from "../hooks/useNavBarState";
import ApiIcon from '@mui/icons-material/Api';

import './component-edits.css';
import {AutoStories} from "@mui/icons-material/";

interface SidebarContentProps {
    user: AccountResponse
    setSidebarOpen: (open: boolean) => void
}

interface SidebarProps {
    user: AccountResponse
    sidebarWidth: number
    sidebarOpen: boolean
    setSidebarOpen: (open: boolean) => void
}

interface SidebarItemProps {
    text: string
    icon: React.ReactNode
    to: string
    hrefTo?: string
    onClick?: () => void
    child?: boolean
}

const SidebarItem = (props: SidebarItemProps) => {
    const sx = props.child ? {pl: 4} : {}
    const location = useLocation()
    return props.hrefTo !== undefined ? <>
        <ListItemButton href={props.hrefTo!!} />
    </> : <ListItemButton sx={sx} component={Link} to={props.to} selected={props.to === location.pathname} onClick={props.onClick} >
        <ListItemIcon>
            {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.text}/>
    </ListItemButton>
}

interface SidebarItemDropdownProps {
    identifier: string
    text: string
    icon: React.ReactNode
    items: SidebarItemProps[]
    setSidebarOpen: (closed: boolean) => void
}

const SidebarDropdown = (props: SidebarItemDropdownProps) => {
    const location = useLocation()
    const navbarSate = useNavBarState()
    const [open, setOpen] = navbarSate.useSidebarMenuState(props.identifier)

    let selected = false

    for (let i = 0; i < props.items.length; i++) if (props.items[i].to === location.pathname) {
        selected = true
    }

    const closeOnClick = (onClick?: () => void) => {
        return () => {
            props.setSidebarOpen(false)
            if (onClick) {
                onClick()
            }
        }
    }

    return <>
        <ListItemButton className={"custom-css-group"} selected={selected} onClick={() => {
            setOpen(!open)
        }}>
            <ListItemIcon className={"custom-css-icon"}>
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.text}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            {props.items.map(item => <SidebarItem key={item.to} child text={item.text} icon={item.icon}
                                                  to={item.to} onClick={closeOnClick(item.onClick)}/>)}
        </Collapse>
    </>
}

const SidebarContent = (props: SidebarContentProps) => {
    const user = props.user

    const sidebarItems: React.ReactNode[] = []
    sidebarItems.push(<SidebarItem key={"/"} text={"Dashboard"} icon={<InfoOutlinedIcon/>} to={"/"}/>)
    sidebarItems.push(<SidebarItem key={`/user/${user.communityId}`} text={"Profile"} icon={<BallotOutlinedIcon/>}
                                   to={`/user/${user.communityId}`}/>)
    sidebarItems.push(<SidebarItem key={"/announcements"} text={"Announcements"} icon={<AnnouncementOutlinedIcon/>}
                                   to={"/announcements"}/>)

    const employeesItems: SidebarItemProps[] = []
    employeesItems.push({text: "Employees", icon: <PersonOutlineOutlinedIcon/>, to: "/employees"})
    if (userHasPermissions(user, "records:viewRecords")) {
        employeesItems.push({text: "Record Lookup", icon: <FindInPageIcon/>, to: "/record-search"})
    }
    if (user.permissions.find((p) => { return p.includes("members:manage") }) !== undefined) {
        employeesItems.push({text: "Activity", icon: <ApiIcon/>, to: "/activity-auditing"})
    }
    if (userHasPermissions(user, "observationReport:view")) {
        employeesItems.push({text: "Observations", icon: <ContentPasteSearchIcon/>, to: "/observations"})
    }
    sidebarItems.push(<SidebarDropdown key={"employees"} identifier={"employees"} text={"Employees"}
                                       icon={<PeopleIconOutlined/>} items={employeesItems}
                                       setSidebarOpen={props.setSidebarOpen}/>)

    sidebarItems.push(<SidebarItem key={"/careers"} text={"Careers"} icon={<CardTravelOutlinedIcon/>}
                                   to={"/careers"}/>)

    const informationItems: SidebarItemProps[] = []
    informationItems.push({text: "Handbook", icon: <AutoStories/>, to: "https://sites.google.com/view/officers-handbook/home"})
    informationItems.push({text: "Resources", icon: <QuizIcon/>, to: "/resources"})
    informationItems.push({text: "Useful Contacts", icon: <EmailIcon/>, to: "/division-lines"})
    sidebarItems.push(<SidebarDropdown key={"information"} identifier={"information"} text={"Information"}
                                       icon={<InfoIcon/>} setSidebarOpen={props.setSidebarOpen}
                                       items={informationItems}/>)


    const internalAffairsItems: SidebarItemProps[] = []
    internalAffairsItems.push({text: "Complaints", icon: <DvrOutlinedIcon/>, to: "/complaints"})
    if (userHasPermissions(user, ["internalAffairs:viewComplaint"]) || userHasPermissions(user, ["internalAffairs:complaintJuniorReviewBoard"]) || userHasPermissions(user, ["internalAffairs:complaintSeniorReviewBoard"])) {
        internalAffairsItems.push({text: "Open Complaints", icon: <ListIcon/>, to: "/internal-affairs"})
    }
    if (userHasPermissions(user, "internalAffairs:viewAllComplaints")) {
        internalAffairsItems.push({text: "Archived Complaints", icon: <ChecklistRtlOutlinedIcon/>, to: "/internal-affairs-archive"})
    }
    if (userHasPermissions(user, "records:viewRecordLogs")) {
        employeesItems.push({text: "Record Logs", icon: <PageviewOutlinedIcon/>, to: "/record/logs"})
    }
    sidebarItems.push(<SidebarDropdown key={"internal_affairs"} identifier={"internal_affairs"}
                                       text={"Internal Affairs"} setSidebarOpen={props.setSidebarOpen}
                                       icon={<GavelOutlinedIcon/>} items={internalAffairsItems}/>)

    sidebarItems.push(<SidebarItem key={"/helpdesk"} text={"Helpdesk"} icon={<ContactSupportOutlinedIcon/>}
                                   to={"/helpdesk"}/>)
    if (userHasPermissions(user, "paychecks:panel")) {
        sidebarItems.push(<SidebarItem key={"/payments"} text={"Paycheck Script Generator"} icon={<SettingsSuggestOutlined/>}
                                       to={"/payments"}/>)
    }

    return (
        <List>
            <ListItem
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: 20
                }}
            >
                <Stack spacing={0.5} alignItems="center">
                    <PoliceAvatar online={true} avatarUrl={user.fullAvatarLink}
                                  color={userHasPermissions(user, "admin:tag") ? "ff0000" : userHasPermissions(user, "management:tag") ? "008080" : userHasPermissions(user, "sitemod:tag") ? "AA336A" : user.rank?.color ?? "white"} userCommunityId={user.communityId}></PoliceAvatar>
                    <Box sx={{fontWeight: "medium"}}>
                        {user.rpName}
                    </Box>
                    <RankChip rank={user.rank?.displayName ?? "Civilian"} color={user.rank?.color}/>
                </Stack>
            </ListItem>
            <Divider/>
            {sidebarItems}
        </List>
    )
}

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
    let theme = useTheme()
    let content = <SidebarContent user={props.user} setSidebarOpen={props.setSidebarOpen}/>
    let useMobileDrawer = useMediaQuery(theme.breakpoints.down("md"))
    const container = window !== undefined ? () => window.document.body : undefined;

    useEffect(() => {
        if (!useMobileDrawer) {
            props.setSidebarOpen(false)
        }
    }, [useMobileDrawer, props]);

    let navColour = theme.palette.background.paper

    let lightMode: any;

    lightMode = theme.palette.mode === "light";

    let drawerProps = {
        sx: {
            backgroundColor: navColour,
            backgroundImage: "none",
            boxShadow: "none",
            border: (lightMode ? "1px solid rgba(0, 0, 0, 0.12)" : "1px solid rgba(255, 255, 255, 0.12) ")
        }
    }

    if (useMobileDrawer) {
        return (
            <Drawer
                container={container}
                variant="temporary"
                PaperProps={drawerProps}
                open={props.sidebarOpen}
                className={"custom-sidebar mode-" + (lightMode ? 'light' : 'dark')}
                onClose={() => props.setSidebarOpen(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', md: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: props.sidebarWidth, background: navColour}
                }}
            >
                {content}
            </Drawer>
        )
    } else {
        return (
            <Drawer
                variant="permanent"
                PaperProps={drawerProps}
                className={"custom-sidebar mode-" + (lightMode ? 'light' : 'dark')}
                sx={{
                    display: {xs: 'none', md: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: props.sidebarWidth, background: navColour}
                }}
                open
            >
                {content}
            </Drawer>
        )
    }

}

export default Sidebar;