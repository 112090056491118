import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {LoginPage} from "./pages/LoginPage";
import {ToSPage} from "./pages/ToSPage";
import {createTheme, CssBaseline, PaletteMode, ThemeProvider, useMediaQuery} from "@mui/material";
import ProfilePageWithRedirect from './pages/ProfilePage';
import {MainPage} from "./pages/MainPage";
import {UserProvider} from "./hooks/useUser";
import {red} from "@mui/material/colors";
import {CreateAwardPage} from "./pages/CreateAwardPage";
import {EditProfilePage} from "./pages/EditProfilePage";
import {PoliceTimeLinePage} from "./pages/PoliceTimeLinePage"
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {NavBarStateProvider} from "./hooks/useNavBarState";
import {CreateAnnouncementPage} from "./pages/CreateAnnouncementPage";
import {AnnouncementPage} from "./pages/AnnouncementPage";
import {AnnouncementMenuPage} from "./pages/AnnoucementMenuPage";
import {EmployeesPage} from "./pages/EmployeesPage";
import {CareersPage} from "./pages/careers/CareersPage";
import {InboxPage} from "./pages/InboxPage";
import {NewApplicationPage} from "./pages/careers/NewApplicationPage"
import {InternalAffairs} from "./pages/internal-affairs/InternalAffairs";
import {InternalAffairsPersonal} from "./pages/internal-affairs/InternalAffairsPersonal";
import {NewAssessmentPage} from "./pages/NewAssessmentPage";
import {EditAssessmentPage} from "./pages/EditAssessmentPage";
import {InternalAffairsComplaintPage} from "./pages/internal-affairs/InternalAffairsComplaintPage";
import {InternalAffairsFinalReportPage} from "./pages/internal-affairs/InternalAffairsFinalReportPage";
import {InternalAffairsNewComplaintPage} from "./pages/internal-affairs/InternalAffairsNewComplaintPage";
import {PaletteModeSwitcherContext} from "./hooks/usePaletteModeSwitcher";
import {getCookie, setCookie} from "typescript-cookie";
import {NewAssessmentQuestionPage} from "./pages/NewAssessmentQuestionPage";
import {EditAssessmentQuestionPage} from "./pages/EditAssessmentQuestionPage";
import {RecordSearchPage} from "./pages/RecordSearchPage"
import {RecordsPage} from "./pages/RecordsPage";
import {UserAssessmentPage} from "./pages/UserAssessmentPage";
import {HelpdeskPage} from "./pages/HelpdeskPage";
import {HelpdeskTicketPage} from "./pages/HelpdeskTicketPage";
import {NotificationsPage} from "./pages/NotificationsPage";
import {PageTemplate} from "./pages/PageTemplate";
import {Breakpoint} from "@mui/system";
import {ArchivedHelpdeskTicketsPage} from "./pages/ArchivedHelpdeskTicketsPage";
import {DivisionLinesPage} from "./pages/DivisionLinesPage";
import {InternalAffairsArchivedComplaintsPage} from "./pages/internal-affairs/InternalAffairsArchivedComplaintsPage";
import {HelpdeskNewTicketPage} from "./pages/HelpdeskNewTicketPage";
import {PaycheckPage} from "./pages/PaycheckPage";
import {GradeAssessmentPage} from "./pages/GradeAssessmentPage";
import {MiniProfileProvider} from "./hooks/useMiniProfile";
import {RecordQueryLogPage} from "./pages/RecordQueryLogPage";
import {ResourcePage} from "./pages/resource/ResourcePage";
import {DivisionResourcePage} from "./pages/resource/DivisionResourcePage";
import {NewResourcePage} from "./pages/resource/NewResourcePage";
import {ViewResourcePage} from "./pages/resource/ViewResourcePage";
import {EditApplicationAssessmentsPage} from "./pages/careers/EditApplicationAssessments";
import {ApplicationMarkingOverviewPage} from "./pages/careers/ApplicationMarkingOverviewPage";
import {ApplicationMarkingPage} from "./pages/careers/ApplicationMarkingPage";
import {NewRankApplicationPage} from "./pages/careers/NewRankApplicationPage";
import {NewTaserApplicationPage} from "./pages/careers/NewTaserApplicationPage";
import {GrantAwardPage} from "./pages/GrantAwardPage";
import {EditApplicationPage} from "./pages/careers/EditApplicationPage";
import {EditAnnouncementPage} from "./pages/EditAnnouncementPage";
import {ActivityAuditOverviewPage} from "./pages/activity-audit/ActivityAuditOverviewPage";
import {ObservationReportListingPage} from "./pages/ObservationReportListingPage";
import {ClosedCareersPage} from "./pages/careers/ClosedCareersPage";
import {DisplayManagementPage} from "./pages/DivisionManagementPage";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
            // main: '#01579b',
        },
        secondary: {
            main: '#2979ff',
        },
        background: {
            default: '#f5f5f5',
        },
        error: {
            main: red.A400,
        },
        mode: "light"
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundImage: "none",
                    boxShadow: "none",
                    border: "1px solid rgba(255, 255, 255, 0.12)"
                }
            }
        }
    }
})

const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#2979ff',
        },
        background: {
            paper: '#1d1d1d'
        },
        error: {
            main: red.A400,
        },
        mode: "dark"
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#1d1d1d",
                    backgroundImage: "none",
                    boxShadow: "none",
                    border: "1px solid rgba(255, 255, 255, 0.12)"
                }
            }
        }
    }
})

interface RouteDefinition {
    path: string,
    element: JSX.Element,
    requiresPageTemplate?: boolean,
    maxWidth?: Breakpoint | false
}

const routes: RouteDefinition[] = [
    {
        path: "/login",
        element: <LoginPage/>,
        requiresPageTemplate: false
    },
    {
        path: "/terms",
        element: <ToSPage/>,
        requiresPageTemplate: false
    },
    {
        path: "/tos",
        element: <ToSPage/>,
        requiresPageTemplate: false
    },
    {
        path: "/user/:communityId/:tab?",
        element: <ProfilePageWithRedirect/>
    },
    {
        path: "/",
        element: <MainPage/>,
    },
    {
        path: "/careers",
        element: <CareersPage/>
    },
    {
        path: "/careers/overview/:careerId/:stage",
        element: <ApplicationMarkingOverviewPage/>,
    },
    {
        path: "/careers/mark/:careerId/:communityId",
        element: <ApplicationMarkingPage/>,
    },
    {
        path: "/new-rank-application",
        element: <NewRankApplicationPage/>,
    },
    {
        path: "/employees",
        element: <EmployeesPage/>,
    },
    {
        path: "/profile/edit",
        element: <EditProfilePage/>,
    },
    {
        path: "/award/create",
        element: <CreateAwardPage/>,
    },
    {
        path: "/timeline",
        element: <PoliceTimeLinePage/>,
    },
    {
        path: "/inbox/:id?",
        element: <InboxPage/>,
    },
    {
        path: "/announcement/create",
        element: <CreateAnnouncementPage/>,
    },
    {
        path: "/announcement/:id",
        element: <AnnouncementPage/>,
        maxWidth: "xl"
    },
    {
        path: "/announcement/edit/:id",
        element: <EditAnnouncementPage/>,
        maxWidth: "xl"
    },
    {
        path: "/announcements",
        element: <AnnouncementMenuPage/>,
        maxWidth: "xl"
    },
    {
        path: "/notifications",
        element: <NotificationsPage/>,
        maxWidth: "xl"
    },
    {
        path: "/new-application/:divisionIdentifier",
        element: <NewApplicationPage/>,
    },
    {
        path: "/new-application-taser",
        element: <NewTaserApplicationPage/>,
    },
    {
        path: "/edit-application/:applicationId",
        element: <EditApplicationAssessmentsPage/>,
    },
    {
        path: "/edit-application-requirements/:applicationType/:divisionIdentifier/:applicationId",
        element: <EditApplicationPage/>,
    },
    {
        path: "/give-award",
        element: <GrantAwardPage/>,
    },
    {
        path: "/complaints",
        element: <InternalAffairsPersonal/>,
    },
    {
        path: "/internal-affairs",
        element: <InternalAffairs/>
    },
    {
        path: "/internal-affairs-archive",
        element: <InternalAffairsArchivedComplaintsPage/>
    },
    {
        path: "/assessments/grade/:id",
        element: <GradeAssessmentPage/>
    },
    {
        path: "/assessments/:accesstype/:id/:type",
        element: <UserAssessmentPage/>
    },
    {
        path: "/assessments/new",
        element: <NewAssessmentPage/>
    },
    {
        path: "/assessments/:id/edit",
        element: <EditAssessmentPage/>
    },
    {
        path: "/assessments/:id/edit/question/new",
        element: <NewAssessmentQuestionPage/>
    },
    {
        path: "/assessments/:id/edit/question/:questionId",
        element: <EditAssessmentQuestionPage/>
    },
    {
        path: "/complaint/:id",
        element: <InternalAffairsComplaintPage/>,
    },
    {
        path: "/final-report/:id",
        element: <InternalAffairsFinalReportPage/>
    },
    {
        path: "/new-complaint",
        element: <InternalAffairsNewComplaintPage/>
    },
    {
        path: "/record-search",
        element: <RecordSearchPage/>
    },
    {
        path: "/records/:search/:tab?",
        element: <RecordsPage/>
    },
    {
        path: "/helpdesk/:division?",
        element: <HelpdeskPage/>
    },
    {
        path: "/helpdesk/ticket/:id",
        element: <HelpdeskTicketPage/>
    },
    {
        path: "/helpdesk/archived/",
        element: <ArchivedHelpdeskTicketsPage/>
    },
    {
        path: "/helpdesk/create/:divisionIdentifier?",
        element: <HelpdeskNewTicketPage/>
    },
    {
        path: "/division-lines",
        element: <DivisionLinesPage/>
    },
    {
        path: "/payments",
        element: <PaycheckPage/>
    },
    {
        path: "/record/logs",
        element: <RecordQueryLogPage/>
    },
    {
        path: "/resources",
        element: <ResourcePage/>
    },
    {
        path: "/resource/:divisionIdentifier/:resourceIdentifier",
        element: <ViewResourcePage/>
    },
    {
        path: "/resources/:divisionIdentifier",
        element: <DivisionResourcePage/>
    },
    {
        path: "/resources/new/:divisionIdentifier",
        element: <NewResourcePage/>
    },
    {
        path: "/activity-auditing",
        element: <ActivityAuditOverviewPage/>
    },
    {
        path: "/observations",
        element: <ObservationReportListingPage/>
    },
    {
        path: "/closed-careers",
        element: <ClosedCareersPage/>
    },
    {
        path: "/division-management/:divisionIdentifier/:tab?",
        element: <DisplayManagementPage/>
    }
]

const routesWithPageTemplate = routes.map((r) => {
    let element = r.element
    if (r.requiresPageTemplate !== false) {
        const containerProps = (r.maxWidth) ? {maxWidth: r.maxWidth} : undefined
        element = <PageTemplate containerProps={containerProps}>
            {element}
        </PageTemplate>
    }

    return {
        path: r.path,
        element: element
    }
})

const router = createBrowserRouter(routesWithPageTemplate);

function setPaletteModeCookie(mode: PaletteMode) {
    setCookie("plpd-theme", mode)
}

function getPaletteModeFromCookie(): PaletteMode | null {
    const cookieValue = getCookie("plpd-theme")
    if (cookieValue === "dark") return "dark"
    else if (cookieValue === "light") return "light"
    else return null
}

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const [paletteMode, setPaletteMode] = useState(getPaletteModeFromCookie())
    const usedPaletteMode = paletteMode ?? (prefersDarkMode ? 'dark' : 'light')

    const theme = (usedPaletteMode === "light") ? lightTheme : darkTheme

    return <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <PaletteModeSwitcherContext.Provider value={{
                setPaletteMode: (type) => {
                    setPaletteMode(type)
                    setPaletteModeCookie(type)
                }
            }}>
                <ThemeProvider theme={theme}>
                    <UserProvider>
                        <MiniProfileProvider>
                            <NavBarStateProvider>
                                <CssBaseline/>
                                <RouterProvider router={router}/>
                            </NavBarStateProvider>
                        </MiniProfileProvider>
                    </UserProvider>
                </ThemeProvider>
            </PaletteModeSwitcherContext.Provider>
        </LocalizationProvider>
    </React.StrictMode>
}

root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
