interface ApplicationRequirementsInter {
    key: string
    displayName: string
}

export const applicationRequirements: ApplicationRequirementsInter[] = [
    { key: "minimum_rank", displayName: "Rank Required" },
    { key: "minimum_role", displayName: "Role Required" },
    { key: "minimum_playtime", displayName: "Playtime Required" },
    { key: "minimum_division_playtime", displayName: "Time performing division duties" },
    { key: "application_cooldown", displayName: "Time since last application" },
    { key: "infraction_cooldown", displayName: "Time since last infraction" },
    { key: "taser_requirement", displayName: "Taser Certification" },
    { key: "division_restriction", displayName: "Must not be a member of" }
]

export const ApplicationRequirements: Map<string, ApplicationRequirementsInter> = new Map(applicationRequirements.map(i => [i.key, i]))