import {LoadingContent} from "../LoadingContent";
import React, {useEffect, useState} from "react";
import {Box, Link, Pagination, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {PaginatedUserSummaryResponseWithRole, UserSummaryResponseWithRole} from "../../data/UserSummary";
import {RankChip} from "../RankChip";

interface RosterProps {
    divisionIdentifier: string
}

export interface RosterRowProps {
    member: UserSummaryResponseWithRole
}

const RosterRow = (props: RosterRowProps) => {
    return <TableRow>
        <TableCell>
            <Link underline="none" component={RouterLink} to={`/user/${props.member.communityId}`}>
            {props.member.nick}
            </Link>
        </TableCell>
        <TableCell>
            <Link underline="none" component={RouterLink} to={`/user/${props.member.communityId}`}>
                {props.member.rpName}
            </Link>
        </TableCell>
        <TableCell>{props.member.badgeNumber}</TableCell>
        <TableCell></TableCell>
        <TableCell><RankChip rank={props.member.policeRank.displayName} color={props.member.policeRank.color}/></TableCell>
        <TableCell>{props.member.divisionRoleDisplayName}</TableCell>
    </TableRow>
}

export const Roster = (props: RosterProps) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const [roster, setRoster] = useState<PaginatedUserSummaryResponseWithRole>({
        members: [],
        count: 0,
        pages: 1
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/division/${props.divisionIdentifier}/roster/${page}`)
                const data = await response.json()
                setRoster(data)
            } catch (error) {
                console.error('Error fetching Rows:', error)
            }
        };
        fetchData()
    }, [page, props.divisionIdentifier])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }

    return <Stack spacing={4}>
            <Box sx={{overflowX: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Steam Name</TableCell>
                            <TableCell>In-Game Name</TableCell>
                            <TableCell>Badge Number</TableCell>
                            <TableCell>Certifications</TableCell>
                            <TableCell>Police Rank</TableCell>
                            <TableCell>Division Rank</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingContent showLoadingSkeleton={true} isLoading={roster.count === 0}>
                            {roster.members && roster.members.map((member) => {
                                return <RosterRow key={member.communityId} member={member}/>
                            })}
                        </LoadingContent>
                    </TableBody>
                </Table>
                <Box sx={{justifyContent: "center", display: "flex", mt: 2, width: "100%"}}>
                    <Pagination color={'secondary'} count={roster.pages} page={page} style={{padding: 8}}
                                onChange={handleChange}/>
                </Box>
            </Box>
        </Stack>
}