import {UserAssessmentQuestionResponse} from "../../data/UserAssessmentQuestionResponse";
import {
    Box, Button,
    Checkbox, Chip, Collapse, darken,
    FormControl,
    FormControlLabel,
    FormGroup, Input, lighten,
    Paper,
    Radio,
    RadioGroup, Stack, Typography, useTheme
} from "@mui/material";
import {EditorContent} from "../editor/EditorContent";
import {clamp, parseIntOrNull, pluralize} from "../../utils";
import React, {PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";
import {TextEditor} from "../editor/TextEditor";
import {QuestionType} from "../../data/QuestionType";
import {UserAssessmentResponse} from "../../data/UserAssessmentResponse";
import {UserAssessmentUserAnswerResponse} from "../../data/UserAssessmentAnswerRespoonse";
import {LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import {UserAssessmentQuestionAnswerResponse} from "../../data/UserAssessmentQuestionAnswerResponse";
import {UserAssessmentFeedback} from "./UserAssessmentFeedback";
import {UserAssessmentFeedbackResponse} from "../../data/UserAssessmentFeedbackResponse";

export type UserAssessmentVariant = "user" | "grader"

interface UserAssessmentQuestionProps {
    assessment: UserAssessmentResponse
    question: UserAssessmentQuestionResponse
    canDeleteFeedback?: boolean
    doSaveAnswers?: (question: UserAssessmentQuestionResponse, answers: UserAssessmentUserAnswerResponse[], silent?: boolean) => void
    doUpdateGrade?: (question: UserAssessmentQuestionResponse, points: number, onSuccess: () => void) => void
    doSubmitFeedback?: (question: UserAssessmentQuestionResponse, feedback: string, isPrivate: boolean, onSuccess: (response: UserAssessmentFeedbackResponse) => void) => void
    doDeleteFeedback?: (question: UserAssessmentQuestionResponse, id: number, onSuccess: () => void) => void
    variant?: UserAssessmentVariant
}

export const UserAssessmentQuestion = (props: UserAssessmentQuestionProps) => {
    const [points, _setPoints] = useState(props.question.awardedPoints)
    const [edited, setEdited] = useState(false)
    const [error, setError] = useState(false)

    const setPoints = useCallback((newPoints: number | null) => {
        if (newPoints !== null) {
            newPoints = clamp(newPoints, 0, props.question.points)
        }
        _setPoints(newPoints)
        setError(newPoints === null)
        setEdited(newPoints !== null && newPoints !== props.question.awardedPoints)
    }, [props.question])

    const onGradeUpdated = () => setEdited(false)

    let content = <></>
    if (props.question.questionType === QuestionType.MULTIPLE_CHOICE) {
        content =
            <MultipleChoiceAnswerDisplay key={props.question.id} question={props.question} assessment={props.assessment}
                                         doSaveAnswers={props.doSaveAnswers}/>
    } else if (props.question.questionType === QuestionType.SHORT_RESPONSE) {
        content =
            <ShortResponseAnswerDisplay key={props.question.id} question={props.question} assessment={props.assessment}
                                        doSaveAnswers={props.doSaveAnswers}/>
    } else if (props.question.questionType === QuestionType.SELECT_MULTIPLE) {
        content =
            <SelectMultipleAnswerDisplay key={props.question.id} question={props.question} assessment={props.assessment}
                                         doSaveAnswers={props.doSaveAnswers}/>
    }

    let awardedPoints = <>{points}</>
    if (props.variant === "grader") {
        awardedPoints =
            <Input inputProps={{style: {textAlign: "center"}}} sx={{maxWidth: "3em"}} placeholder="-" value={points}
                   type="number" error={error}
                   onChange={(event) => {
                       const points = parseIntOrNull(event.target.value)
                       setPoints(points)
                   }}/>
    }

    return <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" id={`question${props.question.order}`}>Question {props.question.order}</Typography>
            <Typography component="div">
                {awardedPoints}{props.assessment.submitted ? "/" : ""}{props.question.points} {pluralize("Point", props.question.points)}
            </Typography>
        </Stack>
        {props.variant === "grader" ? <Collapse orientation="vertical" in={edited}>
            <Button variant="contained" sx={{width: "100%"}} onClick={() => {
                if (props.doUpdateGrade) {
                    props.doUpdateGrade(props.question, points ?? 0, onGradeUpdated)
                }
            }}>
                Save Changes
            </Button>
        </Collapse> : <></>}
        <EditorContent content={props.question.text}/>
        {content}
        {(props.question.questionType === QuestionType.SHORT_RESPONSE || props.question.questionType === QuestionType.PRACTICAL) && props.variant === "grader" ?
            <>
                <Typography variant="h6">Marking Guidelines</Typography>
                <EditorContent content={props.question?.markingGuidelines ?? ""}/>
            </> :
            <></>
        }
        {props.question.feedback.length > 0 || props.variant === "grader" ?
            <UserAssessmentFeedback question={props.question} canDeleteFeedback={props.canDeleteFeedback}
                                    doDeleteFeedback={props.doDeleteFeedback} doSubmitFeedback={props.doSubmitFeedback}
                                    variant={props.variant}/> :
            <></>
        }
    </Stack>
}

interface AnswerDisplayProps {
    assessment: UserAssessmentResponse
    question: UserAssessmentQuestionResponse
    doSaveAnswers?: (question: UserAssessmentQuestionResponse, answers: UserAssessmentUserAnswerResponse[], silent?: boolean) => void
    variant?: UserAssessmentVariant
}

const MultipleChoiceAnswerDisplay = (props: AnswerDisplayProps) => {
    const [answers, setAnswers] = useState(props.question.userAnswers)

    const handleAnswerChange = (_event: React.ChangeEvent<HTMLInputElement>, newAnswer: string) => {
        if (props.variant === "grader") return
        const answerId = parseIntOrNull(newAnswer)
        if (answerId !== null) {
            const answer = {id: -1, answerId: answerId}
            const newAnswers = [answer]
            setAnswers(newAnswers)
            if (props.doSaveAnswers) {
                props.doSaveAnswers(props.question, newAnswers)
            }
        }
    }

    return <FormControl>
        <RadioGroup onChange={handleAnswerChange} value={answers[0]?.answerId ?? ""}>
            {props.question.questionAnswers.map(answer => {
                    return <AnswerContainer key={answer.id} isSelected={answers[0]?.answerId === answer.id} answer={answer}>
                        <FormControlLabel value={answer.id}
                                          control={<Radio disabled={props.assessment.submitted}/>}
                                          label={<EditorContent content={answer.text}/>}/>
                    </AnswerContainer>
                }
            )}
        </RadioGroup>
    </FormControl>
}

const ShortResponseAnswerDisplay = (props: AnswerDisplayProps) => {
    const editorRef = useRef<LexicalEditor>(null)
    const editorChanged = useRef(false)

    const trySaveAnswers = useCallback(() => {
        if (props.variant === "grader") return
        if (editorRef.current && editorChanged.current) {
            editorRef.current.getEditorState().read(() => {
                const text = $generateHtmlFromNodes(editorRef.current!)
                if (props.doSaveAnswers) {
                    props.doSaveAnswers(props.question, [{shortResponse: text}], true)
                }
                editorChanged.current = false
            })
        }
    }, [props])

    const intervalRef = useRef(setInterval(() => {
        trySaveAnswers()
    }, 30000))

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            clearInterval(intervalRef.current)
        }
    }, []);
    return props.assessment.submitted ? <Paper variant="outlined" sx={{padding: "16px"}}>
            <EditorContent content={props.question.userAnswers[0]?.shortResponse ?? ""}/>
        </Paper> :
        <>
            <TextEditor ref={editorRef} initialContent={props.question.userAnswers[0]?.shortResponse}
                        onChange={() => {
                            editorChanged.current = true
                        }}
                        onBlur={trySaveAnswers}/>
        </>
}

const SelectMultipleAnswerDisplay = (props: AnswerDisplayProps) => {
    const [answers, setAnswers] = useState(props.question.userAnswers)

    const handleAnswerChange = (newAnswer: number, checked: boolean) => {
        if (props.variant === "grader") return
        const newAnswers = answers.filter(a => a.answerId !== newAnswer)
        if (checked) {
            newAnswers.push({answerId: newAnswer})
        }
        if (newAnswers.length <= props.question.correctAnswers) {
            setAnswers(newAnswers)
            if (props.doSaveAnswers) {
                props.doSaveAnswers(props.question, newAnswers)
            }
        }
    }
    return <>
        <Typography>Select {props.question.correctAnswers} {pluralize("answer", props.question.correctAnswers)}:</Typography>
        <FormControl component="fieldset" variant="standard">
            <FormGroup>
                {props.question.questionAnswers.map(answer => {
                        const checked = answers.find(a => a.answerId === answer.id) !== undefined
                        return <AnswerContainer key={answer.id} isSelected={checked} answer={answer}>
                            <FormControlLabel
                                label={<EditorContent content={answer.text}/>}
                                control={<Checkbox checked={checked}
                                                   disabled={props.assessment.submitted}
                                                   onChange={event =>
                                                       handleAnswerChange(answer.id, event.target.checked)}
                                />}
                            />
                        </AnswerContainer>
                    }
                )}
            </FormGroup>
        </FormControl>
    </>
}

interface AnswerContainerProps extends PropsWithChildren {
    isSelected: Boolean
    answer: UserAssessmentQuestionAnswerResponse
}

const AnswerContainer = (props: AnswerContainerProps) => {
    const theme = useTheme()
    let backgroundColor = "#ffffff00"
    let chipColor: "success" | "error" | "warning" | undefined = undefined
    let chipMessage = ""
    if (props.isSelected && props.answer.correct === true) {
        backgroundColor = theme.palette.success.main
        chipColor = "success"
        chipMessage = "Correct Answer"
    } else if (props.isSelected && props.answer.correct === false) {
        backgroundColor = theme.palette.error.main
        chipColor = "error"
        chipMessage = "Incorrect Answer"
    } else if (!props.isSelected && props.answer.correct) {
        backgroundColor = theme.palette.warning.main
        chipColor = "warning"
        chipMessage = "Correct Answer"
    }
    const getColor = theme.palette.mode === "light" ? lighten : darken
    return <Box bgcolor={getColor(backgroundColor, 0.9)} sx={{
        border: `1px solid ${backgroundColor}`,
        paddingX: "8px",
        marginY: "8px",
        borderRadius: "4px"
    }}>
        <Stack direction="row" width={"100%"} justifyContent={"space-between"} alignItems="center">
            {props.children}
            {props.answer.correct || props.isSelected ? <Chip color={chipColor} label={chipMessage}/> : <></>}
        </Stack>
    </Box>
}