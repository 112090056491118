import React, {useState} from "react";
import useApiCall from "../hooks/CancellableApiCall";
import {PayeeResponse} from "../data/PayeeResponse";
import {
    Box,
    Button, Input,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {PoliceUser} from "../components/PoliceUser";

export const PaycheckPage = () => {
    const [blacklistedIds, setBlacklistIds] = useState<number[]>([])
    const [paymentScript, setPaymentScript] = useState<string | null>(null)
    const [extraPay, setExtraPay] = useState<{ [communityId: string]: number }>({})
    const loadPayees = useApiCall<PayeeResponse[]>({
        url: `/api/paycheck/`
    })

    let pageContent
    if (!paymentScript) {
        pageContent = <>
            <Box sx={{overflowX:"auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nick</TableCell>
                        <TableCell>Bank ID</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>Payment Amount</TableCell>
                        <TableCell>Additional Bonus</TableCell>
                        <TableCell>Give Bonus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadPayees.data && loadPayees.data.map((payee) => {
                        return <TableRow key={payee.payeeCommunityId}>
                            <TableCell>
                                <PoliceUser
                                    user={{nick: payee.payeeNick, communityId: payee.payeeCommunityId.toString()}}/>
                            </TableCell>
                            <TableCell> {payee.bankAccount.split(".")[0]} </TableCell>
                            <TableCell> {payee.paycheckType.substring(9).replace("-", " ")} </TableCell>
                            <TableCell> ${payee.paycheckAmount} </TableCell>
                            <TableCell> <Input id={`${payee.payeeCommunityId}-extra`} placeholder={"0"} onChange={(event) => {
                                setExtraPay(prevState => ({
                                    ...prevState,
                                    [payee.payeeCommunityId]: event.target.value
                                }))
                            }}/> </TableCell>
                            <TableCell>
                                <Select
                                    id='select-allowed'
                                    value={Number(blacklistedIds.includes(payee.payeeCommunityId))}
                                    onChange={(event) => {
                                        if (Number(event.target.value) === 1) {
                                            setBlacklistIds([...blacklistedIds, payee.payeeCommunityId])
                                        } else {
                                            setBlacklistIds(blacklistedIds.filter(blacklistedId => {
                                                return payee.payeeCommunityId !== blacklistedId
                                            }))
                                        }
                                    }}
                                >
                                    <MenuItem key={0} value={0}>Yes</MenuItem>
                                    <MenuItem key={1} value={1}>No</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                    })
                    }
                </TableBody>
            </Table>
            </Box>
            <Button color="success" onClick={() => {
                let paymentScript = `echo "Starting payroll script"\n`
                loadPayees.data?.forEach(payee => {
                    let bonus
                    if (!isNaN(Number(extraPay[payee.payeeCommunityId]))) {
                        bonus = Number(extraPay[payee.payeeCommunityId])
                    } else {
                        bonus = 0
                    }
                    if (!blacklistedIds.includes(payee.payeeCommunityId)) {
                        let paycheck = Number(payee.paycheckAmount) + bonus
                        let bankaccount = payee.bankAccount.split(".")[0]
                        paymentScript += `echo "Sending $${paycheck} to ${payee.payeeNick}"\nscam givemoneybank ${bankaccount} ${paycheck} "PLPD Paycheck"\n`
                    }
                })
                setPaymentScript(paymentScript)
            }}>Generate Script</Button>
        </>
    } else {
        pageContent = <>
            <Typography variant="h6">Payment Script</Typography>
            <Typography variant="subtitle1" sx={{whiteSpace: "pre-wrap"}}>{paymentScript}</Typography>
            <Button color="info" variant="text" onClick={() => setPaymentScript(null)}>
                Return
            </Button>
        </>
    }

    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        {pageContent}
    </Paper>
}