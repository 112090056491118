import React, {useCallback, useState} from "react"
import {Alert, Button, Input, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, Typography} from "@mui/material";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {RankResponse} from "../../data/RankResponse";
import {LoadingContent} from "../../components/LoadingContent";
import {useNavigate} from "react-router-dom";
import {ApplicationRequirements} from "./ApplicationRequierments";
import {allRequirements} from "./RankRequirements";

type alertType = "success" | "error" | "info"

interface NewApplicationPanelProps {
    ranks: RankResponse[]
}

interface applicationRequirement {
    type: string,
    param: string | number
}

function NewRankApplicationPanel(props: NewApplicationPanelProps) {
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<alertType>("success")
    const [alertText, setAlertText] = useState("")
    const [newRoleApplicationRole, setNewRoleApplicationRole] = useState(-1)
    const [newRoleApplicationDescription, setNewRoleApplicationDescription] = useState("")
    const [newRoleApplicationRankRequirements, setNewRoleApplicationRankRequirements] = useState<applicationRequirement[]>([])
    const navigate = useNavigate()

    const setAlert = useCallback((type: alertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const createNewApplication = (roleId: number, description: string, requirements: applicationRequirement[] | null) => {
        makeApiCall({
            url: `/api/careers/new`,
            method: "POST",
            body: {
                divisionRoleId: roleId,
                description: description,
                requirements: requirements,
                type: "rank",
            },
            onLoadedCallback: () => {
                setAlert("success", "Application created")
                navigate("/careers")
            },
            onError: () => {
                setAlert("error", "Application failed to create due to a issue")
                setNewRoleApplicationRankRequirements([])
            }
        })
    }

    return <>
        <Paper style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <Stack spacing={1}>
                <Typography variant="h5">New Rank Application</Typography>
                <InputLabel id="new-role-application-select-rank-label">Rank</InputLabel>
                <Select id="new-role-application-select-rank" value={newRoleApplicationRole}
                        label="Rank" onChange={(event) => {
                    setNewRoleApplicationRole(event.target.value as number)
                }}>
                    {props.ranks.map((rank) =>
                        <MenuItem key={rank.rankId} value={rank.rankId}>{rank.displayName}</MenuItem>
                    )}
                </Select>
                <Input multiline rows={4} placeholder="Application Description..."
                       onChange={(event) => setNewRoleApplicationDescription(event.target.value)}/>
                <Button disabled={(newRoleApplicationRole === 0)} variant="contained" onClick={() => {
                    createNewApplication(newRoleApplicationRole, newRoleApplicationDescription, newRoleApplicationRankRequirements)
                }}>
                    Create new application
                </Button>
            </Stack>
        </Paper>
        <ApplicationRequirements
            requirementTable={newRoleApplicationRankRequirements}
            ranks={props.ranks}
            setAlert={setAlert}
            requirementsToInclude = {allRequirements.filter(requirement =>
                !requirement.shouldUseDivisionRoles && !requirement.shouldUseDivisions && !(requirement.requirementIdentifier === "minimum_division_playtime")
            )}
        />
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </>
}

export const NewRankApplicationPage = () => {
    const loadRanks = useApiCall<RankResponse[]>({
        initialUrl: "/api/rank/subordinates"
    })

    return <LoadingContent isLoading={
        loadRanks.isLoading || !loadRanks.data
    }>
        <NewRankApplicationPanel ranks={loadRanks.data!}/>
    </LoadingContent>
}