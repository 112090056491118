import React, {useCallback, useState} from "react";
import {Alert, Button, Input, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {AssessmentInfoResponse} from "../../data/assessment/AssessmentInfoResponse";
import {EditorContent} from "../../components/editor/EditorContent";
import {AlertType} from "../../data/AlertType";

interface EditApplicationAssessmentsPageContentProps {
    assessments: AssessmentInfoResponse[],
    divisionIdentifier: string,
    careerId: number
}

function EditApplicationAssessmentsPageContent(props: EditApplicationAssessmentsPageContentProps) {
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        <Table sx={{mb:2}}>
            <TableHead>
                <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Stage</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Passing Score</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.assessments?.map(assessment => {
                            return <TableRow>
                                <TableCell> {assessment.title} </TableCell>
                                <TableCell>
                                    <Input id="stage" sx={{maxWidth: "35px"}} value={assessment.stage} onChange={(event) => {
                                        makeApiCall({
                                            url: `/api/careers/edit/${assessment.id}/stage`,
                                            method: "POST",
                                            body: {
                                                newStage: event.target.value
                                            },
                                            onLoadedCallback: () => {
                                                navigate(`/careers`)
                                                setAlert("success", "Reodered assessments")
                                            },
                                            onError: () => {
                                                setAlert("error", "Failed to re-oder assessments")
                                            }

                                        })
                                    }}/>
                                </TableCell>
                                <TableCell> <EditorContent content={assessment.description}/> </TableCell>
                                <TableCell> {assessment.passingScore}% </TableCell>
                                <TableCell> <Button component={RouterLink}
                                                    to={`/assessments/${assessment.id}/edit`}
                                                    color="info">Edit Stage</Button> </TableCell>
                            </TableRow>
                        }
                    )
                }
            </TableBody>
        </Table>
        <Button component={RouterLink}
                to={`/careers`}
                color="info">Return</Button>
        <Button sx={{ml:1}} component={RouterLink}
                to={`/assessments/new?careerId=${props.careerId}`}
                color="success">New Stage</Button>
        <Button sx={{ml:1}} color="success" onClick={() => {
            makeApiCall({
                url: `/api/careers/${props.careerId}/state`,
                method: "PATCH",
                body: {
                    state: "open"
                },
                onLoadedCallback: () => {
                    navigate(`/careers`)
                },
                onError: () => {
                    setAlert("error", "Failed to open application")
                }
            })
        }}>Open Application</Button>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Paper>
}

export const EditApplicationAssessmentsPage = () => {
    const params = useParams()

    const assessments = useApiCall<AssessmentInfoResponse[]>({
        url: `/api/careers/edit/${params.applicationId}/assessment-overview`
    })

    return <LoadingContent
        isLoading={(assessments.isLoading || !assessments)}>
        <EditApplicationAssessmentsPageContent
            assessments={assessments.data!!}
            divisionIdentifier={params.divisionIdentifier?.replaceAll("-", "_") ?? "none"}
            careerId={Number(params.applicationId) ?? -1}
        />
    </LoadingContent>
}