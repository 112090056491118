import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {Alert, DialogContent, Input, Snackbar} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import {makeApiCall, UseApiCallResponseData} from "../../hooks/CancellableApiCall";
import {TextEditor} from "../../components/editor/TextEditor";
import {$generateHtmlFromNodes} from '@lexical/html';
import {UserSelector} from "../../components/UserSelector";
import {UserResponse} from "../../data/UserResponse";
import {LexicalEditor} from "lexical";
import {AlertType} from "../../data/AlertType";
import {
    InternalAffairsStatementQuestionResponse
} from "../../data/internal-affairs/InternalAffairsStatementQuestionResponse";
import {InternalAffairsComplaintResponse} from "../../data/internal-affairs/InternalAffairsComplaintResponse";

interface InternalAffairsNewStatementFormProps {
    id: number
    defaultTarget: string
    parentApiCall: UseApiCallResponseData<InternalAffairsComplaintResponse>
}

export default function InternalAffairsNewStatementForm(props: InternalAffairsNewStatementFormProps) {
    const [open, setOpen] = useState(false);
    const [statementUser, setStatementUser] = useState<UserResponse | null>(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const statementEditorRef = useRef<LexicalEditor>(null)
    const [alertText, setAlertText] = useState("")
    const [questions, setQuestions] = useState<InternalAffairsStatementQuestionResponse[]>([])

    const initialStatementContent = "<p class=\"editor-paragraph\" dir=\"ltr\"><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">What happened: </strong></b></p><p class=\"editor-paragraph\"><br></p><p class=\"editor-paragraph\" dir=\"ltr\"><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">Where did it happen: </strong></b></p><p class=\"editor-paragraph\"><br></p><p class=\"editor-paragraph\" dir=\"ltr\"><b><strong class=\"editor-text-bold\" style=\"white-space: pre-wrap;\">When did it happen: </strong></b></p>"
    
    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button onClick={() => {setOpen(true)}}>New Statement</Button>
            <Dialog open={open} onClose={handleClose}>
                {questions.length === 0 && statementUser !== null ?
                    <Alert severity="warning">Please create at least one question</Alert> : <></>}
                {questions.length !== 0 ?
                    <Alert severity="info">Blank questions will be automatically removed</Alert> : <></>}
                <DialogContent>
                    <UserSelector user={statementUser} onUserSelected={setStatementUser} extendedUserInformation={true}/>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                            Statement
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <TextEditor ref={statementEditorRef} initialContent={initialStatementContent}/>
                </DialogContent>
                <DialogContent>
                    {questions.map((question, idx) => {
                        return <div key={idx}>
                            <Input id="reason" sx={{width: "90%"}} multiline rows={2} placeholder="Question..."
                                   onChange={(event) => {
                                       question.question = event.target.value}}/>
                        </div>
                    })}
                </DialogContent>
                <DialogContent>
                    <Button color={"success"} disabled={statementUser === null} onClick={() => {
                        setQuestions([...questions, {
                            id:  questions.length,
                            question: "",
                            response: undefined
                        }])
                    }}>New Question</Button>
                </DialogContent>
                <DialogContent>
                    <Button color={"success"} disabled={statementUser === null || questions.length === 0} onClick={() => {
                        if (statementEditorRef.current) {
                            statementEditorRef.current.getEditorState().read(() => {
                                let content = $generateHtmlFromNodes(statementEditorRef.current!)

                                makeApiCall({
                                    url: `/api/internal-affairs/statement-request/`,
                                    method: "POST",
                                    body: {
                                        complaintId: props.id,
                                        request: content,
                                        providerIdentifier: statementUser?.communityId,
                                        questions: questions
                                    },
                                    onLoadedCallback: () => {
                                        setAlert("success", "Statement Requested")
                                        props.parentApiCall.refresh()
                                        handleClose()
                                    },
                                    onError: () => {
                                        setAlert("error", "Statement could not be requested")
                                    }
                                })
                            })
                        }
                    }}>Submit Request</Button>
                </DialogContent>
            </Dialog>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}