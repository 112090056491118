import React from "react";
import {
    Box,
    Button,
    Chip, IconButton,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@mui/material";
import useApiCall from "../../hooks/CancellableApiCall";
import useUser from "../../hooks/useUser";
import {LoadingContent} from "../../components/LoadingContent";
import {AccountResponse} from "../../data/AccountResponse";
import {
    InternalAffairsLimitedComplaintResponse
} from "../../data/internal-affairs/InternalAffairsLimitedComplaintResponse";
import {formatTimestamp} from "../../utils";
import {Link as RouterLink} from "react-router-dom";
import {InternalAffairsComplaintStatus} from "../../data/internal-affairs/InternalAffairsComplaintStatus";
import {
    InternalAffairsComplaintClassification
} from "../../data/internal-affairs/InternalAffairsComplaintClassification";
import {InternalAffairsComplaintOutcome} from "../../data/internal-affairs/InternalAffairsComplaintOutcome";
import {RecordTypes} from "../../data/internal-affairs/RecordTypes";
import ErrorIcon from '@mui/icons-material/Error';

interface PersonalComplaintsDashboardProps {
    user: AccountResponse
    finalReportViews: boolean
    investigatorViews: boolean
    complaintAdminViews: boolean
}

interface ComplaintRowProps {
    complaint: InternalAffairsLimitedComplaintResponse
}

interface ComplaintTableProps {
    complaints: InternalAffairsLimitedComplaintResponse[] | null
    header: string
    personal?: boolean
}

export const ComplaintTable = (props: ComplaintTableProps) => {
    return <>
        <Typography variant="subtitle1">{props.header}</Typography>
        <Box sx={{overflowX:"auto"}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Complaint ID</TableCell>
                    <TableCell>Defendant</TableCell>
                    <TableCell>Creator</TableCell>
                    <TableCell>Classification</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Outcome</TableCell>
                    <TableCell>Action Taken</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.complaints && props.complaints.map((complaint) => {
                    return <AccessibleComplaintRow key={complaint.id} complaint={complaint}/>
                })
                }
            </TableBody>
        </Table>
        </Box>
    </>
}

const AccessibleComplaintRow = (props: ComplaintRowProps) => {
    let statusText
    if (props.complaint.status === "awaiting_investigator") {
        statusText = <Chip color={"warning"} label={"Awaiting Investigator"}/>
    } else if (props.complaint.status === "complaint_frozen") {
        statusText = statusText = <Chip color={"error"} label={"Complaint Frozen"}/>
    } else {
        statusText = InternalAffairsComplaintStatus.get(props.complaint.status)?.displayName
    }
    return <TableRow>
        <TableCell> {props.complaint.displayId ?? props.complaint.id} </TableCell>
        <TableCell>
            <Link underline="none"
                  component={RouterLink}
                  to={`/user/${props.complaint.defendantCommunityId}`}
            > {props.complaint.defendantName} </Link>
        </TableCell>
        <TableCell>
            <Link underline="none"
                  component={RouterLink}
                  to={`/user/${props.complaint.creatorCommunityId}`}
            > {props.complaint.creatorName} </Link>
        </TableCell>
        <TableCell> {InternalAffairsComplaintClassification.get(props.complaint.classification ?? "pending")?.displayName} </TableCell>
        <TableCell>
            {statusText} {props.complaint.overdue ? <Tooltip title="Complaint overdue. Please resolve immediately">
            <IconButton>
                <ErrorIcon color={"error"} />
            </IconButton>
        </Tooltip> : <></>}
        </TableCell>
        <TableCell> {InternalAffairsComplaintOutcome.get(props.complaint.outcome ?? "pending")?.displayName} </TableCell>
        <TableCell> {RecordTypes.get(props.complaint.actionTaken ?? "")?.displayName} </TableCell>
        <TableCell> {formatTimestamp(props.complaint.creationDate)} </TableCell>
        <TableCell> <Button component={RouterLink} to={`/complaint/${props.complaint.displayId ?? props.complaint.id}`}
                            color="success">View Complaint</Button> </TableCell>
    </TableRow>
}

function ComplaintsDashboard(props: PersonalComplaintsDashboardProps) {
    const loadAssignedComplaints = useApiCall<InternalAffairsLimitedComplaintResponse[]>({
        initialUrl: `/api/internal-affairs/complaint/${props.user.communityId}/assigned`,
        redirectOnUnauthorized: false
    })

    const loadOpenComplaints = useApiCall<InternalAffairsLimitedComplaintResponse[]>({
        initialUrl: `/api/internal-affairs/open`,
        redirectOnUnauthorized: false
    })

    const loadFinalReportedComplaints = useApiCall<InternalAffairsLimitedComplaintResponse[]>({
        initialUrl: `/api/internal-affairs/final-reported`,
        redirectOnUnauthorized: false
    })

    let investigatorView = <></>
    let finalReportView = <></>
    let complaintAdminView = <></>

    if (props.investigatorViews) {
        investigatorView =
            <Paper variant={"outlined"} style={{
                marginTop: "16px",
                padding: "16px"
            }}>
                <ComplaintTable complaints={loadAssignedComplaints.data} header={`Complaints you are assigned to`}/>
            </Paper>
    }

    if (props.finalReportViews) {
        finalReportView =
            <Paper variant={"outlined"} style={{
                marginTop: "16px",
                padding: "16px"
            }}>
                <ComplaintTable complaints={loadFinalReportedComplaints.data} header={`Final Reports pending review`}/>
            </Paper>
    }

    if (props.complaintAdminViews) {
        complaintAdminView =
            <Paper variant={"outlined"} style={{
                marginTop: "16px",
                padding: "16px"
            }}>
                <ComplaintTable complaints={loadOpenComplaints.data} header={`Complaints you can manage`}/>
            </Paper>
    }

    return <Stack spacing={1}>
        {investigatorView}
        {complaintAdminView}
        {finalReportView}
    </Stack>
}

export const InternalAffairs = () => {
    const userData = useUser()

    const user = userData.user

    return (
        <LoadingContent isLoading={
            userData.isLoading || !user
        }>
            <ComplaintsDashboard
                user={user!!}
                finalReportViews={userData.hasPermissions("internalAffairs:complaintJuniorReviewBoard")}
                investigatorViews={userData.hasPermissions("internalAffairs:viewComplaint")}
                complaintAdminViews={userData.hasPermissions("internalAffairs:viewAllComplaints")}
            />
        </LoadingContent>
    )
}