import React, {useEffect, useState} from "react";
import {
    Box,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import {ObservationReportPaginatedResponse} from "../data/ObservationReportPaginatedResponse";
import {LoadingContent} from "../components/LoadingContent";
import {formatTimestamp} from "../utils";
import {PoliceUser} from "../components/PoliceUser";
import {ObservationReportViewForm} from "../components/profile/ObservationReportViewForm";
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

export const ObservationReportListingPage = () => {
    const [page, setPage] = useState(1)
    const [observationReports, setObservationReports] = useState<ObservationReportPaginatedResponse>({
        observationReports: [],
        count: 0,
        pages: 1
    })
    const [filter, setFilter] = useState("-")

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/observation-report/list/${page}/${filter}`)
                const data = await response.json()
                setObservationReports(data)
            } catch (error) {
                console.error('Error fetching ORs:', error)
            }
        };
        fetchData()
    }, [page, filter])

    return <Stack spacing={1}>
        <Paper variant={"outlined"} style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <Box sx={{display: "flex"}}>
                <TextField fullWidth variant="standard" label="Search..." value={filter}
                   onChange={(event) => {
                       setPage(1)
                       setFilter(event.target.value)
                   }}
                />
                <Tooltip title="Use - for a unfiltered seach, everything else is searched with case insensitive steam names, or communityids">
                    <QuestionMarkOutlinedIcon sx={{width: "20px", height: "20px"}}/>
                </Tooltip>
            </Box>
            <LoadingContent showLoadingSkeleton={true} isLoading={observationReports.count === 0}>
                <TableContainer sx={{overflowX: "auto"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Officer</TableCell>
                                <TableCell>Supervisor</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {observationReports.observationReports.map((report) => <TableRow key={report.id.toString()}>
                                <TableCell>
                                    <PoliceUser user={{communityId: report.employeeCommunityId, nick: report.employeeNick}}/>
                                </TableCell>
                                <TableCell>
                                    <PoliceUser user={{communityId: report.issuerCommunityId, nick: report.issuerNick}}/>
                                </TableCell>
                                <TableCell>{formatTimestamp(report.date)}</TableCell>
                                <TableCell>
                                    <ObservationReportViewForm id={report.id}/>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LoadingContent>
        </Paper>
        <Box sx={{justifyContent: "center", display: "flex", width: "100%", mt: 2}}>
            <Pagination color={'secondary'} count={observationReports.pages} page={page} onChange={handleChange}/>
        </Box>
    </Stack>
}