import React, {useEffect, useState} from "react";
import {
    Box,
    Link,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {RecordQueryPageinationResponse} from "../data/RecordQueryPageinationResponse";
import {formatTimestamp} from "../utils";

export const RecordQueryLogPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const [queryLogs, setQueryLogs] = useState<RecordQueryPageinationResponse>({
        totalLogs: 1,
        totalPages: 1,
        logs: []
    });

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/records/logs/${page}`)
                const data = await response.json()
                setQueryLogs(data)
            } catch (error) {
                console.error('Error fetching logs:', error)
            }
        };
        fetchData()
    }, [page])

    const totalPages = queryLogs.totalPages

    return <Stack spacing={1}>
        <Typography variant={"h6"}>Record Logs</Typography>
        <Paper variant={"outlined"} style={{
            padding: "16px",
            overflowX: "auto"
        }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Supervisor</TableCell>
                        <TableCell>Employee</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Reason</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {queryLogs.logs && queryLogs.logs.map((log) => {
                        return <TableRow>
                            <TableCell> <Link underline="none"
                                              component={RouterLink}
                                              to={`/user/${log.supervisorCommunityId}`}
                            > {log.nick} </Link> </TableCell>
                            <TableCell> <Link underline="none"
                                              component={RouterLink}
                                              to={`/user/${log.employeeCommunityId}`}
                            > {log.employeeNick} </Link> </TableCell>
                            <TableCell> {log.accessType} </TableCell>
                            <TableCell> {log.reason} </TableCell>
                            <TableCell> {formatTimestamp(log.date)} </TableCell>
                        </TableRow>
                    })
                    }
                </TableBody>
            </Table>
        </Paper>
        <Box sx={{justifyContent: "center", display: "flex", width: "100%", mt: 2}}>
            <Pagination color={'secondary'} count={totalPages} page={page} onChange={handleChange}/>
        </Box>
    </Stack>
}